import { Component, Input, EventEmitter, Output } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";

@Component({
  selector: "app-ncsa-banner",
  templateUrl: "./ncsa-banner.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    LocalizationModule,
  ],
  styleUrls: ["./ncsa-banner.component.scss"],
})
export class NCSABannerComponent {
  @Input() yes = false;

  @Output() choose = new EventEmitter<boolean>();

  select(value: boolean) {
    this.yes = value;
    this.choose.emit(value);
  }

  constructor() {}
}
