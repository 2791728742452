<header>
  <div
    class="wrap-header"
  >
    <div class="section-left">
      <!-- <div class="icon-fa-bars" #menuBtn (click)="toggleLeftPanel()" [innerHTML]="IconBars | safe: SafeTypes.html"></div> -->
      <a
        href="/"
        [ngClass]="{ searchIsOpen: hidePanelsOnOpenSearch }"
        [innerHTML]="IconUventex | safe : SafeTypes.html"
        class="logo"
      ></a>
      <app-header
        [isDiffUrl]="isDiffUrl"
        [ngClass]="{ searchIsOpen: hidePanelsOnOpenSearch }"
      ></app-header>

      <app-search
        class="adaptive-only"
        [isDiffUrl]="isDiffUrl"
        [ngClass]="{ fullWidth: hidePanelsOnOpenSearch }"
        (displaySearch)="displaySearch($event)"
      ></app-search>
    </div>
    <div class="section-right">
      <div *ngIf="!isDashboard" class="filter-mobile" (click)="openFiltersMobile()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
        >
          <path
            d="M0.122688 0.715869C0.328938 0.278369 0.766438 0.000244141 1.25081 0.000244141H14.7508C15.2352 0.000244141 15.6727 0.278369 15.8789 0.715869C16.0852 1.15337 16.0227 1.66899 15.7164 2.04399L10.0008 9.02837V13.0002C10.0008 13.3784 9.78831 13.7252 9.44769 13.894C9.10706 14.0627 8.70394 14.0284 8.40081 13.8002L6.40081 12.3002C6.14769 12.1127 6.00081 11.8159 6.00081 11.5002V9.02837L0.282063 2.04087C-0.0210617 1.66899 -0.0866868 1.15024 0.122688 0.715869Z"
            fill="#616161"
          />
        </svg>
        <span *ngIf="filtersApplied" class="red-dot"></span>
      </div>
      <div class="hub-management">
        <studio-local-selector
          [hidePanelsOnOpenSearch]="hidePanelsOnOpenSearch"
          [isDiffUrl]="isDiffUrl"
        ></studio-local-selector>
      </div>
    </div>
  </div>
</header>
<!-- 
    <div class="loader main" *ngIf="loaderService.isLoading$ | async;">
      <div class="loader-spinner">
        <img src="../../../../assets/img/loader.svg" alt="" />
      </div>
    </div> -->

<div class="wrap-main" [ngClass]="{ 'no-padding': isDashboard }">
  <div class="main1">
    <div class="filter" *ngIf="showFilters">
      <app-filter
        [title]="'SPORTS'"
        [maxItems]="8"
        [type]="FilterType.sports"
      ></app-filter>
      <app-filter
        [title]="'LEAGUES'"
        [maxItems]="7"
        [type]="FilterType.leagues"
      ></app-filter>
      <app-filter
        [title]="'COUNTRIES'"
        [maxItems]="4"
        [type]="FilterType.countries"
      ></app-filter>

      <a href="https://www.uventexlabs.com" target="_blank">
        <img class="lef-banner" src="/assets/img/left-add-banner.png" />
      </a>
    </div>
    <div
      class="right-block"
      [ngClass]="{ 'full-width': !showFilters || isDiffUrl || fullWidth }"
    >
      <div class="outlet">
        <router-outlet></router-outlet>
        <ng-template #dialogComponentOutlet></ng-template>
        <ng-template #sidebarComponentOutletRight></ng-template>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<app-mobile-filters-dialog
  *ngIf="isDialogOpen"
  (close)="closeDialog()"
></app-mobile-filters-dialog>
