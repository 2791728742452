import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import {
  ImageCropperModule,
  ImageCropperComponent,
  ImageTransform,
  ImageCroppedEvent,
} from "ngx-image-cropper";

@Component({
  selector: "app-img-crop-input-field",
  templateUrl: "./img-crop-input-field.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgClass,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    LocalizationModule,
    ImageCropperModule,
  ],
  styleUrls: ["./img-crop-input-field.component.scss"],
})
export class ImgCropInputFieldComponent {
  @ViewChild("cropper") cropper!: ImageCropperComponent;
  imageChangedEvent: any;

  transform: ImageTransform = { rotate: 0 };
  croppedImage: any = null;
  cropperEnabled = true;

  @ViewChild("fileInput") fileInput!: ElementRef;
  @ViewChild("videoElement") videoElement!: ElementRef;
  @ViewChild("canvasElement") canvasElement!: ElementRef;
  @ViewChild("cameraInput") cameraInput!: ElementRef;

  showCameraModal = false;
  isMobile = this.detectMobile();

  @Input() imageUrl: string | undefined;
  @Output() imageSelected = new EventEmitter<any>();

  constructor() {}

  cancelImage() {
    this.croppedImage = null;
    this.imageChangedEvent = null;
    this.imageUrl = undefined;
    this.cropperEnabled = true;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = "";
    }
    this.imageSelected.emit("");
  }

  fileChange(event: any): void {
    this.cropperEnabled = true;
    this.imageChangedEvent = event;
  }

  async camera(cameraInput: any) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });
      // You can now attach this stream to a video element for live preview
    } catch (error) {
      console.error("Camera access denied or unavailable", error);
    }
  }

  private detectMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  async openCamera() {
    if (this.isMobile) {
      this.cameraInput.nativeElement.click();
    } else {
      await this.initializeDesktopCamera();
    }
  }

  private async initializeDesktopCamera() {
    try {
      const constraints = {
        video: {
          facingMode: "user", // 'user' for front camera, 'environment' for rear
          width: { ideal: 720 },
          height: { ideal: 720 },
        },
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.showCameraModal = true;

      // Wait for modal to render before setting video source
      setTimeout(() => {
        this.videoElement.nativeElement.srcObject = stream;
        this.videoElement.nativeElement.play();
      }, 100);
    } catch (error) {
      console.error("Error accessing camera:", error);
      this.cameraInput.nativeElement.click(); // Fallback to file upload
    }
  }

  captureImage() {
    const video = this.videoElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    const context = canvas.getContext("2d");

    // Set canvas dimensions to match video frame
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw current video frame to canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert canvas to Blob
    canvas.toBlob((blob: Blob | null) => {
      if (blob) {
        // Create a File object similar to a regular file input
        const file = new File([blob], "camera-capture.png", {
          type: "image/png",
          lastModified: Date.now(),
        });

        // Create synthetic event for existing fileChange handler
        const event = {
          target: {
            files: [file],
          },
        };

        this.fileChange(event as any);
      }
    }, "image/png");

    setTimeout(() => {
      this.closeCamera();
    }, 1500);
  }

  closeCamera() {
    this.showCameraModal = false;
    const stream = this.videoElement.nativeElement.srcObject;
    if (stream) {
      stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    }
  }

  toggleCropper(): void {
    if (this.imageChangedEvent) {
      this.cropperEnabled = !this.cropperEnabled;
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.objectUrl;
    this.imageSelected.emit(event);
  }

  rotate(degrees: number): void {
    const originalCropperEnabled = this.cropperEnabled;
    if (!originalCropperEnabled) {
      this.imageChangedEvent.src = this.croppedImage;
      this.cropperEnabled = true;
    }
    this.transform = {
      ...this.transform,
      rotate: (this.transform.rotate || 0) + degrees,
    };

    if (!originalCropperEnabled) {
      setTimeout(() => {
        this.cropperEnabled = false;
      }, 250);
    }
  }
}
