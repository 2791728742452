import { CommonModule, Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from "@angular/router";
import * as moment from "moment";
import { LocalizationModule } from "src/app/components/localization";
import { SafePipe } from "src/app/shared/pipes/safe.pipe";
import { TruncatePipe } from "src/app/shared/pipes/truncate.pipe";
import { EventsService } from "src/app/shared/constants/events.service";
import { Event } from "../../../shared/models/event.model";
import { MatTabsModule } from "@angular/material/tabs";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { Observable, map, of } from "rxjs";
import { isIOS } from "../../../utils/detect-ios.function";
import { IconBrows } from "../../../../assets/icons/brows";
import { IconDirections } from "../../../../assets/icons/directions";
import { IconTriangleRight } from "../../../../assets/icons/triangle-right";
import { IconEnvelopeWhite } from "../../../../assets/icons/envelope-white";
import { SafeTypes } from "../../../shared/constants/safe-types.enum";
import {
  CarouselModule,
  OwlOptions,
  SlidesOutputData,
} from "ngx-owl-carousel-o";
import { DialogService } from "src/app/shared/services/dialog.service";
import { ImgSlideComponent } from "../img-slide/img-slide.component";
import { Select, Store } from "@ngxs/store";
import { UserState } from "../../../store/user/user.store";
import { User } from "../../../shared/models/user.model";
import { SignInSignUpComponent } from "../../../components/signin-signup/signin-signup.component";
import { MatDialog } from "@angular/material/dialog";
import { LoaderService } from "../../../shared/services/loader.service";
import { filter } from "rxjs/operators";
import { NavigationService } from "../../../shared/services/navigation.service";
import { MatMenuModule } from "@angular/material/menu";
import { environment } from "../../../../environments/environment";
import { TranslocoService } from "@ngneat/transloco";
import { ToastrService } from "ngx-toastr";

export enum MenuItemType {
  event = "event",
  pricing = "pricing",
  timetable = "timetable",
  schedule = "schedule",
  competitors = "competitors",
  liveScore = "liveScore",
  medals = "medals",
  standings = "standings",
  flows = "flows",
  statistics = "statistics",
}

@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    SafePipe,
    LocalizationModule,
    TruncatePipe,
    RouterModule,
    MatTabsModule,
    ClipboardModule,
    CarouselModule,
    ImgSlideComponent,
    MatMenuModule,
  ],
  providers: [NavigationService],
})
export class EventComponent implements OnInit {
  processedImages: any[] = [];
  currentLang: string = "en";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public eventsService: EventsService,
    private dialogService: DialogService,
    public location: Location,
    private dialog: MatDialog,
    public loaderService: LoaderService,
    public navigationService: NavigationService,
    private transloco: TranslocoService,
    private toastr: ToastrService,
    private store: Store
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.router.navigateByUrl(event.url);
      });
  }

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  @Select(UserState.authUser)
  public authUser$!: Observable<User>;
  user: User | undefined;
  isAdmin = false;

  public eventId!: number;
  public moment = moment;
  public event!: Observable<Event>;
  public currentEvent!: Event | null;
  public clipBoardValue: string = "";
  public daysLeft!: number;
  public IconeBrows = IconBrows;
  public IconDirections = IconDirections;
  public IconTriangleRight = IconTriangleRight;
  public IconEnvelopeWhite = IconEnvelopeWhite;
  public SafeTypes = SafeTypes;
  public selectedImage!: string;
  public isCopied = false;
  public breadcrumbs: string[] = [];
  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: [
      "<div class='slide-prev'></div>",
      "<div class='slide-next'></div>",
    ],
    nav: true,
    center: true,
    autoplay: true,
    items: 1,
    autoWidth: true,
    margin: 8,
  };
  public images!: any[];
  public urls: string[] = [];
  previousUrl!: string;

  protected readonly MenuItemType = MenuItemType;

  public activeMenuItem = MenuItemType.event;
  public menu: any;

  ngOnInit(): void {
    this.transloco.langChanges$.subscribe((lang) => {
      const currentLang = lang.split("-")[0];
      this.currentLang = currentLang;
    });
    this.currentEvent = null;
    this.eventsService
      .getEvent(this.route.snapshot.params["eventId"])
      .subscribe(async (event: any) => {
        // const daysLeftMs = event.regEndDate - Date.now();
        // this.daysLeft = Math.ceil(daysLeftMs / (1000 * 3600 * 24));
        const daysLeft = moment(event.regEndDate).diff(moment.now(), "days");
        this.daysLeft = daysLeft >= 0 ? daysLeft + 1 : 0;
        this.clipBoardValue = document.location.href;
        event.phoneArray = event.contactPhone
          ? event.contactPhone.split(",").map((phone: string) => phone.trim())
          : [];
        event.emailArray = event.contactEmail
          ? event.contactEmail.split(",").map((email: string) => email.trim())
          : [];

        this.currentEvent = event;
        this.event = of(event);

        for (const i of event.eventPhotos) {
          i.url = i.url.replace(/\+/g, "%2B");
        }
        this.images = event.eventPhotos
          .filter(
            (photo: any) =>
              photo.type === "EVENT_BANNER" ||
              photo.type === "EVENT_INFO" ||
              photo.type === "GALLERY"
          )
          .sort((a: any, b: any) => {
            if (a.type === "EVENT_BANNER" && b.type !== "EVENT_BANNER")
              return -1;
            if (b.type === "EVENT_BANNER" && a.type !== "EVENT_BANNER")
              return 1;
            return 0; // Maintain original order for other types
          });
        await this.processImages();
        this.initMenu();
      });

    window.scroll(0, 0);

    this.authUser$.subscribe((user) => {
      this.user = user;
      this.initMenu();
    });

    this.isAdmin$.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
      this.initMenu();
    });
  }

  async processImages() {
    this.processedImages = [];

    for (const image of this.images) {
      try {
        const processedImage = await this.loadImage(image);
        this.processedImages.push(processedImage);
      } catch (error) {
        console.error("Error loading image:", image.url, error);
      }
    }
  }

  loadImage(image: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = image.url;

      img.onload = () => {
        resolve({
          ...image,
          width: img.width,
          height: img.height,
          aspectRatio: img.width / img.height,
        });
      };

      img.onerror = (err) => {
        reject(err);
      };
    });
  }

  getAspectRatioClass(image: {
    width: number;
    height: number;
    aspectRatio: number;
  }): string {
    if (!image || !image.aspectRatio) {
      return "standard-image"; // Normal aspect ratios
    }
    if (image.aspectRatio < 0.45) {
      return "x-narrow-image";
    } else if (image.aspectRatio < 0.8) {
      return "narrow-image";
    } else if (image.aspectRatio > 1) {
      return "wide-image"; // Very wide images
    }
    return "standard-image"; // Normal aspect ratios
  }

  getReturnUrl() {
    let returnUrl = window.location.href;
    // if (returnUrl.indexOf(eventId) === -1) returnUrl += (returnUrl[returnUrl.length - 1] === "/" ? "" : "/") + eventId;
    returnUrl = encodeURIComponent(returnUrl).replaceAll("%2F", "___");

    return returnUrl;
  }

  initMenu() {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    let returnUrl: string = this.getReturnUrl();
    const configItems = this.currentEvent?.eventConfig?.configItems;
    this.menu = this.eventsService.initMenu(
      this.currentEvent?.id,
      this.currentEvent?.superEventId,
      configItems,
      this.user,
      authToken,
      returnUrl,
      this.isAdmin
    );
  }

  preventNavigation(event: any, venue: any) {
    if (!venue?.maps?.length) {
      event.preventDefault(); // Prevents redirection
    }
  }

  getMapHref(l: any) {
    if (l?.maps.length)
      return isIOS()
        ? `https://maps.apple.com/?q=${l.zipCode || ""},${
            l.country.name || ""
          },${l.city || ""},${l.address || ""}`
        : `https://www.google.com/maps?q=${l.zipCode || ""},${
            l.country.name || ""
          },${l.city || ""},${l.address || ""}`;
    else return;
  }

  getLocation(venue: any): string {
    const address: string[] = [];
    let ad = venue?.address ? venue.address : "";
    let city = venue?.city ? venue.city : "";
    let state = venue?.state?.name ? venue.state.name : "";
    let province = venue?.province;
    if (province) {
      city = province;
    }
    let country = venue?.country?.name ? venue.country.name : "";
    address.push(ad.trim(), city.trim(), state.trim(), country.trim());
    return address.filter((a) => a).join(", ");
  }

  copyToClipBoard(text: any) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.toastr.success("Copied to clipboard");
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error("Failed to copy to clipboard");
      });
  }

  copyLocationToClipBoard() {
    const location =
      (this.currentEvent?.venue.name ?? "") +
      " " +
      this.getLocation(this.currentEvent?.venue);
    this.copyToClipBoard(location);
  }

  changeImage(image: string) {
    this.selectedImage = image;
  }

  changedByNav(data: SlidesOutputData) {
    if (data.slides?.length) {
      this.selectedImage = data.slides[0].id;
    }
  }

  async openImg(params: { url: any; banners: any[] }) {
    await this.dialogService.addCustomDialog(ImgSlideComponent, {
      link: params.url,
      banners: params.banners,
    });
  }

  public onCopy(): void {
    this.isCopied = !this.isCopied;
    setTimeout(() => (this.isCopied = !this.isCopied), 2000);
  }

  public handleRegisterNow() {
    // todo redirect to event page
    this.authUser$.subscribe((user) => {
      if (user) {
        if (this.currentEvent?.selfRegistrationUrl) {
          let token = localStorage.getItem("token");
          let returnUrl = encodeURIComponent(window.location.href).replaceAll(
            "%2F",
            "___"
          );
          window.location.href =
            this.currentEvent?.selfRegistrationUrl.replace(
              "{token}",
              token as string
            ) + `;sportsHubUrl=${returnUrl}`;
        } else {
          const eventId = this.route.snapshot.paramMap.get("eventId");
          this.router.navigateByUrl("registration/" + eventId);
        }
      } else {
        const dialogRef = this.dialog.open(SignInSignUpComponent, {
          autoFocus: "dialog",
          restoreFocus: false,
          data: { signInAndClose: true },
        });
      }
    });
  }

  handleBuyTickets() {
    // todo redirect to event page
    this.authUser$.subscribe((user) => {
      if (user) {
        if (!this.currentEvent) {
          return;
        }
        let token = localStorage.getItem("token");
        let returnUrl = encodeURIComponent(window.location.href).replaceAll(
          "%2F",
          "___"
        );

        window.location.href =
          this.currentEvent?.selfRegistrationUrl.replace(
            "{token}",
            token as string
          ) + `;sportsHubUrl=${returnUrl}`;
        const url = `${environment.EVENT_PORTAL_URL}GWTClient.html?&locale=${this.currentLang}#tickets;id=${this.currentEvent.superEventId};applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
        window.location.href = url;
      } else {
        const dialogRef = this.dialog.open(SignInSignUpComponent, {
          autoFocus: "dialog",
          restoreFocus: false,
          data: { signInAndClose: true },
        });
      }
    });
  }

  public checkRegistrationEnabled(event: any) {
    let result = false;

    if (event?.eventConfig?.isTrackAndField) {
      result = true;
    }

    if (event?.isRegistrationActive) {
      //console.log(`# Registration for event [${event.name}] is Active. Enabling ...`);
      result = true;
    }

    return result;
  }

  goBack() {
    this.location.back();
  }

  navigateToDownload(url: string) {
    if (url) {
      window.location.href = url;
    }
  }
}
