<button
  mat-button
  class="user-icon"
  [ngClass]="{ 'dashboard-mobile': isDashboard }"
  *ngIf="isDashboard"
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
>
  <img
    *ngIf="user.pictureUrl"
    class="user-img-sm"
    src="{{ user.pictureUrl }}"
  />
  <mat-icon *ngIf="!user.pictureUrl">account_circle</mat-icon>
</button>

<button
  mat-button
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  class="user-icon"
  [ngClass]="{ 'dashboard-desktop': isDashboard }"
>
  <img
    *ngIf="user.pictureUrl"
    class="user-img-sm"
    src="{{ user.pictureUrl }}"
  />
  <mat-icon *ngIf="!user.pictureUrl">account_circle</mat-icon>
</button>

<mat-menu #menu="matMenu" [xPosition]="'before'" class="user-action-mennu">
  <div class="user">
    <div class="header-row">
      <div class="email">
        <a href="mailto:{{ user.email }}">{{ user.email }}</a>
      </div>

      <svg
        (click)="menuTrigger.closeMenu()"
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5625 6L11.6875 9.15625C12.0938 9.53125 12.0938 10.1562 11.6875 10.5312L11 11.2188C10.625 11.625 10 11.625 9.625 11.2188L6.5 8.09375L3.34375 11.2188C2.96875 11.625 2.34375 11.625 1.96875 11.2188L1.28125 10.5312C0.875 10.1562 0.875 9.53125 1.28125 9.15625L4.40625 6L1.28125 2.875C0.875 2.5 0.875 1.875 1.28125 1.5L1.96875 0.8125C2.34375 0.40625 2.96875 0.40625 3.34375 0.8125L6.5 3.9375L9.625 0.8125C10 0.40625 10.625 0.40625 11 0.8125L11.6875 1.5C12.0938 1.875 12.0938 2.5 11.6875 2.875L8.5625 6Z"
          fill="#BAB9BA"
        />
      </svg>
    </div>

    <div class="user-icon">
      <img
        class="user-img"
        src="{{
          user.pictureUrl
            ? user.pictureUrl
            : '/assets/icons/user-img-avatar.svg'
        }}"
      />
    </div>
    <div class="user-info">
      <div class="name">
        {{
          user.fullName ? user.fullName : user.firstName + " " + user.lastName
        }}
      </div>
      <div
        class="role"
        *ngIf="
          (!user.studioAccount || !user.studioAccount.roles) &&
          accountHolderView
        "
      >
        {{ "ACCOUNT_HOLDER" | transloco }}
      </div>
      <div class="role" *ngFor="let role of user.studioAccount?.roles">
        {{ role.name | transloco }}
      </div>
    </div>
    <div class="manage-account" (click)="manageAccount()" *ngIf="!isAdmin">
      {{ "MANAGE_ACCOUNT" | transloco }}
    </div>
  </div>

  <mat-divider></mat-divider>
  <div class="actions">
    <button mat-menu-item (click)="openMeetDialog('0ms', '0ms')" *ngIf="false">
      <div
        [innerHTML]="IconFlag | safe : SafeTypes.html"
        class="action-icon"
      ></div>
      <span class="action-title">{{ "MEETS" | transloco }}</span>
    </button>
    <button mat-menu-item (click)="openEventDialog('0ms', '0ms')" *ngIf="false">
      <div
        [innerHTML]="IconEvents | safe : SafeTypes.html"
        class="action-icon"
      ></div>
      <span class="action-title">{{ "EVENTS" | transloco }}</span>
    </button>
    <button mat-menu-item disabled *ngIf="false">
      <div
        [innerHTML]="IconStudios | safe : SafeTypes.html"
        class="action-icon"
      ></div>
      <span class="action-title">{{ "STUDIOS" | transloco }}</span>
    </button>
    <button mat-menu-item (click)="dashboard()">
      <div
        [innerHTML]="IconHome | safe : SafeTypes.html"
        class="action-icon"
      ></div>
      <span class="action-title">{{ "DASHBOARD" | transloco }}</span>
    </button>
    <mat-divider class="bottom-divider"></mat-divider>
    <button *ngIf="isAdmin" mat-menu-item (click)="handleLoginAs()">
      <div
        [innerHTML]="IconLoginAs | safe : SafeTypes.html"
        class="action-icon"
      ></div>
      <span class="action-title">{{ "LOGIN_AS" | transloco }}</span>
    </button>
    <button *ngIf="isLoginAs$ | async" mat-menu-item (click)="endSession()">
      <div
        [innerHTML]="IconLoginAs | safe : SafeTypes.html"
        class="action-icon"
      ></div>
      <span class="action-title">{{ "END_SESSION" | transloco }}</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <div
        [innerHTML]="IconSignOut | safe : SafeTypes.html"
        class="action-icon"
      ></div>
      <span class="action-title">{{ "SIGN_OUT" | transloco }}</span>
    </button>
  </div>
</mat-menu>
