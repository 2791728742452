<ng-template #breadcrumbsTemplate>
  <div class="first-row-c">
    <div class="breadcrumbs-row">
      <div class="breadcrumbs">
        <div class="breadcrumbs-content">
          <img
            class="breadcrumb-home"
            src="/assets/icons/breadcrumb-home.svg"
            (click)="router.navigateByUrl('/')"
          />

          <ng-container *ngFor="let item of breadcrumbs; let i = index">
            <img
              *ngIf="showFirstBreadcrumb || i !== 0"
              class="breadcrumb-icon"
              alt="arrow"
              src="/assets/icons/dashboard/breadcrumb.svg"
            />
            <a
              *ngIf="
                (showFirstBreadcrumb || i !== 0) && i < breadcrumbs.length - 1
              "
              [routerLink]="item.url"
            >
              <span class="bread-span">{{ item.name | transloco }}</span>
            </a>
            <span *ngIf="i === breadcrumbs.length - 1" class="bread-span">{{
              item.name | transloco
            }}</span>
          </ng-container>
        </div>
        <img
          src="/assets/icons/menu-bars.svg"
          class="burger-icon"
          (click)="displayMenu()"
        />
      </div>
    </div>

    <div
      class="action-search-item"
      [formGroup]="actionsSearchFormGroup"
      *ngIf="!isEventAccountHolderView"
    >
      <mat-form-field appearance="fill" class="action-search">
        <input
          matInput
          type="text"
          [placeholder]="'SEARCH' | transloco"
          formControlName="actionSearch"
          (input)="filterActions()"
          (focus)="showDropdown = true"
          (blur)="hideDropdown()"
        />
        <button matSuffix>
          <i
            class="fas search-icon"
            [class.fa-search]="
              !(leaguesFormGroup.get('actionSearch')?.valueChanges | async)
            "
            [class.fa-times]="
              leaguesFormGroup.get('actionSearch')?.valueChanges | async
            "
          ></i>
        </button>
      </mat-form-field>

      <div
        class="action-search-drop-down-list"
        *ngIf="showDropdown && filteredActionItems.length > 0"
      >
        <div
          *ngFor="let actionItem of filteredActionItems"
          class="dropdown-item"
          (click)="executeAction(actionItem)"
        >
          <img src="{{ actionItem.img }}" alt="{{ actionItem.title }}" />
          <span>{{ actionItem.title }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="main-container" *ngIf="isAdminViews">
  <ng-container *ngTemplateOutlet="breadcrumbsTemplate"></ng-container>
  <div class="header-row" *ngIf="!isFinancePerformance && !isWhiteLabelSettings">
    <div class="uventex-logo-container">
      <img
        class="uventex-logo-1"
        alt="uventex logo"
        src="/assets/icons/dashboard/uventex-logo1.svg"
      />
      <img
        class="uventex-logo-2"
        alt="uventex logo"
        src="/assets/icons/dashboard/uventex-logo2.svg"
      />
    </div>

    <div class="header-actions">
      <ng-container *ngFor="let action of headerAdminActions; let i = index">
        <a
          class="header-action"
          clickStopPropagation
          [href]="getAdminHrefForOption(action.option)"
        >
          <div class="circle">
            <img
              class="ic-header-action"
              [alt]="action.alt"
              [src]="action.icon"
            />
          </div>
          <span>{{ action.label | transloco }}</span>
        </a>

        <div
          class="vertical-delimiter"
          *ngIf="i == headerAdminActions.length - 2"
        ></div>
      </ng-container>
    </div>
  </div>

  <div class="actions-row" *ngIf="isAdminView">
    <ng-container *ngFor="let action of adminRowActions">
      <a
        *ngIf="!action.routerLink && action.option"
        class="action-item"
        clickStopPropagation
        [href]="getAdminHrefForOption(action.option)"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isAccountsView">
    <ng-container *ngFor="let action of adminRowAccountActions">
      <a
        *ngIf="!action.routerLink && action.option"
        class="action-item"
        clickStopPropagation
        [href]="getAdminHrefForOption(action.option)"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isFinancesView">
    <ng-container *ngFor="let action of adminRowFinancesActions">
      <a
        *ngIf="!action.routerLink && action.option"
        class="action-item"
        clickStopPropagation
        [href]="getAdminHrefForOption(action.option)"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="w-100" *ngIf="isWhiteLabelSettings">
    <app-white-label-settings></app-white-label-settings>
  </div>

  <div class="w-100" *ngIf="isFinancePerformance">
    <app-financial-performance></app-financial-performance>
  </div>
</div>

<div class="main-container" *ngIf="isEventViews">
  <ng-container *ngTemplateOutlet="breadcrumbsTemplate"></ng-container>

  <div class="header-row" style="align-items: center">
    <div
      class="item-selector"
      *ngIf="!(isAdmin$ | async) && events?.length > 0"
    >
      <app-searchable-custom-select
        [searchable]="false"
        (filterChanged)="searchEvent($event)"
        (itemSelected)="onItemSelected($event)"
      ></app-searchable-custom-select>
    </div>

    <div class="item-selector" *ngIf="isAdmin$ | async">
      <app-searchable-custom-select
        (filterChanged)="searchEvent($event)"
        (itemSelected)="onItemSelected($event)"
      ></app-searchable-custom-select>
    </div>

    <div
      class="header-actions"
      *ngIf="selectedEvent !== null && !isMedicalView"
    >
      <ng-container *ngFor="let action of headerEventActions; let i = index">
        <a
          class="header-action"
          clickStopPropagation
          *ngIf="
            ((!action.privilege || isEventPrivilegeSet(action.privilege)) &&
              !isAdmin) ||
            !action.id ||
            action.id !== 3
          "
          [href]="getHrefForEventPrivilege(action.privilege)"
        >
          <div
            [ngClass]="{
              circle: i < headerEventActions.length - 1,
              'c-img': i >= headerEventActions.length - 1
            }"
          >
            <img
              [ngClass]="{
                'ic-header-action': i < headerEventActions.length - 1,
                'c-img': i >= headerEventActions.length - 1
              }"
              [alt]="action.alt"
              [src]="action.icon"
            />
          </div>
          <span>{{ action.label | transloco }}</span>
        </a>

        <div
          class="vertical-delimiter"
          *ngIf="i == headerEventActions.length - 2"
        ></div>
      </ng-container>

      <a
        class="header-action"
        clickStopPropagation
        *ngIf="isEventPrivilegeSet(Privileges.CALL_MEDIC)"
        (click)="openCallMedicDialog($event)"
      >
        <div class="call-medics">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="25"
            viewBox="0 0 28 25"
            fill="none"
          >
            <path
              d="M5.25 24.5V5.25H7V2.625C7 1.20312 8.14844 0 9.625 0H18.375C19.7969 0 21 1.20312 21 2.625V5.25H22.75V24.5H5.25ZM10.5 3.5V5.25H17.5V3.5H10.5ZM28 7.875V21.875C28 23.3516 26.7969 24.5 25.375 24.5H24.5V5.25H25.375C26.7969 5.25 28 6.45312 28 7.875ZM3.5 24.5H2.625C1.14844 24.5 0 23.3516 0 21.875V7.875C0 6.45312 1.14844 5.25 2.625 5.25H3.5V24.5ZM19.25 13.125C19.25 12.6875 18.8125 12.25 18.375 12.25H15.75V9.625C15.75 9.1875 15.3125 8.75 14.875 8.75H13.125C12.6328 8.75 12.25 9.1875 12.25 9.625V12.25H9.625C9.13281 12.25 8.75 12.6875 8.75 13.125V14.875C8.75 15.3672 9.13281 15.75 9.625 15.75H12.25V18.375C12.25 18.8672 12.6328 19.25 13.125 19.25H14.875C15.3125 19.25 15.75 18.8672 15.75 18.375V15.75H18.375C18.8125 15.75 19.25 15.3672 19.25 14.875V13.125Z"
              fill="#E15F5F"
            />
          </svg>
        </div>
        <span>{{ "CALL_MEDICS" | transloco }}</span>
      </a>
    </div>

    <div
      class="add-medical-note"
      *ngIf="isMedicalView"
      (click)="openNotesDialog($event)"
    >
      <button class="add-button">+</button>
      <span class="add-note">{{ "ADD_NOTE" | transloco }}</span>
    </div>
  </div>

  <div class="actions-row" *ngIf="isEventView">
    <ng-container *ngFor="let action of eventViewActions">
      <a
        *ngIf="!action.routerLink && action.option"
        class="action-item"
        clickStopPropagation
        [href]="getAdminHrefForOption(action.option)"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege) &&
          action.click &&
          action.toggle !== null
        "
        style="position: relative"
        (click)="handleMenuClick(action)"
        class="action-item"
        clickStopPropagation
      >
        <div class="action-item-nav">
          <img
            *ngIf="action.toggle"
            class="ic-toggle"
            alt="toggle icon"
            [src]="
              (action.toggle | async)
                ? '/assets/icons/dashboard/ic-toggle-on.svg'
                : '/assets/icons/dashboard/ic-toggle-off.svg'
            "
          />
          <span
            class="span-toggle"
            *ngIf="action.toggle | async"
            title="{{ 'ON' | transloco }}"
            >{{ "ON" | transloco }}</span
          >
          <span
            class="span-toggle"
            *ngIf="!(action.toggle | async)"
            title="{{ 'OFF' | transloco }}"
            >{{ "OFF" | transloco }}</span
          >
        </div>
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          !action.privilege &&
          action.show() &&
          action.click
        "
        (click)="handleMenuClick(action)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div
    class="actions-row"
    *ngIf="isEventManagementAvailable() && isEventManagementView"
  >
    <ng-container *ngFor="let action of eventmanagementActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege) &&
          action.click &&
          action.toggle !== null
        "
        style="position: relative"
        (click)="handleMenuClick(action)"
        class="action-item"
        clickStopPropagation
      >
        <div class="action-item-nav">
          <img
            *ngIf="action.toggle"
            class="ic-toggle"
            alt="toggle icon"
            [src]="
              (action.toggle | async)
                ? '/assets/icons/dashboard/ic-toggle-on.svg'
                : '/assets/icons/dashboard/ic-toggle-off.svg'
            "
          />
          <span
            class="span-toggle"
            *ngIf="action.toggle | async"
            title="{{ 'ON' | transloco }}"
            >{{ "ON" | transloco }}</span
          >
          <span
            class="span-toggle"
            *ngIf="!(action.toggle | async)"
            title="{{ 'OFF' | transloco }}"
            >{{ "OFF" | transloco }}</span
          >
        </div>
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          !action.privilege &&
          action.show
        "
        (click)="handleMenuClick(action)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="
          action.admin &&
          isAdmin &&
          !action.routerLink &&
          !action.click &&
          !action.hidden
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="
          action.admin &&
          isAdmin &&
          !action.routerLink &&
          action.click &&
          !action.hidden
        "
        (click)="handleMenuClick(action)"
        [routerLink]=""
        clickStopPropagation
        class="action-item"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isEventManagementScheduleView">
    <ng-container *ngFor="let action of eventManagementScheduleActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege) &&
          !action.click
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="
          !action.admin && !action.routerLink && action.show && action.click
        "
        (click)="handleMenuClick(action)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege) &&
          action.click &&
          action.toggle !== null
        "
        style="position: relative"
        (click)="handleMenuClick(action)"
        class="action-item"
        clickStopPropagation
      >
        <div class="action-item-nav">
          <img
            *ngIf="action.toggle"
            class="ic-toggle"
            alt="toggle icon"
            [src]="
              (action.toggle | async)
                ? '/assets/icons/dashboard/ic-toggle-on.svg'
                : '/assets/icons/dashboard/ic-toggle-off.svg'
            "
          />
          <span
            class="span-toggle"
            *ngIf="action.toggle | async"
            title="{{ 'ON' | transloco }}"
            >{{ "ON" | transloco }}</span
          >
          <span
            class="span-toggle"
            *ngIf="!(action.toggle | async)"
            title="{{ 'OFF' | transloco }}"
            >{{ "OFF" | transloco }}</span
          >
        </div>
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isReportsAvailable() && isEventReportsView">
    <ng-container *ngFor="let action of eventReportsActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.admin && isAdmin && !action.routerLink"
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isEventReportFinanceView">
    <ng-container *ngFor="let action of eventReportsFinanceActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.admin && isAdmin && !action.routerLink"
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isEventReportCompetitorView">
    <ng-container *ngFor="let action of eventReportsCompetitorActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.admin && isAdmin && !action.routerLink"
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isEventReportResultsView">
    <ng-container *ngFor="let action of eventReportsResultsActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.admin && isAdmin && !action.routerLink"
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>

      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isEventReportSchoolView">
    <ng-container *ngFor="let action of eventReportsSchoolActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isEventReportMedicalView">
    <ng-container *ngFor="let action of eventReportsMedicalActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isEventReportStaffView">
    <ng-container *ngFor="let action of eventReportsStaffActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isEventReportPrintView">
    <ng-container *ngFor="let action of eventReportsPrintActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="actions-row" *ngIf="isSettingsAvailable() && isEventSettingsView">
    <ng-container *ngFor="let action of eventSettingsActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div
    class="actions-row"
    *ngIf="isSettingsAvailable() && isEventEditorSettingsView"
  >
    <ng-container *ngFor="let action of eventSettingEditorActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
      <a
        *ngIf="action.routerLink"
        class="action-item-folder"
        style="position: relative"
        clickStopPropagation
        [routerLink]="action.routerLink"
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div class="new-view" *ngIf="isWaiverAndEditor">
    <app-waiver-and-info [event]="selectedEvent"></app-waiver-and-info>
  </div>

  <div class="actions-row" *ngIf="isEventSettingsStaffManagementView">
    <ng-container *ngFor="let action of eventSettingsStaffManagementActions">
      <a
        *ngIf="
          !action.admin &&
          !action.routerLink &&
          action.privilege &&
          isEventPrivilegeSet(action.privilege)
        "
        [href]="getHrefForEventPrivilege(action.privilege)"
        class="action-item"
        clickStopPropagation
      >
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>

  <div *ngIf="isMedicalView" class="w-100">
    <app-medical-dashboard></app-medical-dashboard>
  </div>

  <div class="actions-row" *ngIf="isEventAccountHolderView">
    <ng-container *ngFor="let action of accountHoldersActions">
      <a [href]="action.url" class="action-item" clickStopPropagation>
        <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
        <span>{{ action.label | transloco }}</span>
      </a>
    </ng-container>
  </div>
</div>

<div class="main-container" *ngIf="isLeagueViews">
  <ng-container *ngTemplateOutlet="breadcrumbsTemplate"></ng-container>

  <div
    class="header-row"
    style="align-items: center"
    [formGroup]="leaguesFormGroup"
  >
    <div class="item-selector" *ngIf="isAdmin === false && leagues?.length > 0">
      <mat-form-field appearance="fill">
        <mat-select formControlName="league">
          <mat-select-trigger>
            <div class="selected-item" *ngIf="selectedLeague">
              <span class="item-name">{{ selectedLeague.name }}</span>
            </div>
          </mat-select-trigger>
          <mat-option
            *ngFor="let league of leagues"
            (click)="handleLeagueChange(league)"
            [value]="league.id"
          >
            <div class="item-option">
              <span class="item-name">{{ league.name }}</span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--<div class="league-search-item" *ngIf="isAdmin === true">
            <mat-form-field appearance="fill" class="league-search">
                <input matInput type="text" [placeholder]="'SEARCH' | transloco " formControlName="leagueSearch">

                <button matSuffix (click)="clearLeagueSearch()">
                    <i
                            class="fas search-icon"
                            [class.fa-search]="!(leaguesFormGroup.get('leagueSearch')?.valueChanges | async)"
                            [class.fa-times]="leaguesFormGroup.get('leagueSearch')?.valueChanges | async"
                    ></i>
                </button>
            </mat-form-field>
        </div>-->

    <div class="actions-row" *ngIf="selectedLeague">
      <ng-container *ngFor="let action of leagueActions">
        <a
          *ngIf="
            !action.admin &&
            !action.routerLink &&
            action.leaguePrivilege &&
            isLeaguePrivilegeSet(action.leaguePrivilege)
          "
          [href]="
            getHrefForLeaguePrivilege(action.leaguePrivilege, action.foreign)
          "
          class="action-item"
          clickStopPropagation
        >
          <img class="ic-row-action" [alt]="action.alt" [src]="action.icon" />
          <span>{{ action.label | transloco }}</span>
        </a>
      </ng-container>

      <a
        class="action-item"
        clickStopPropagation
        [href]="getHrefForLeagueParticipantReport()"
        *ngIf="isLeaguePrivilegeSet(Privileges.EDIT_COMPETITORS)"
      >
        <svg
          width="29"
          height="32"
          viewBox="0 0 29 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.5 8.0625C27.875 8.0625 29 9.1875 28.9375 10.5V19.9375C28.9375 20.875 28.875 21.8125 28.625 22.6875L27 29.6875C26.6875 31.0625 25.4375 32 24.0625 32H11.75C10.75 32 9.8125 31.5625 9.3125 30.8125L1.4375 20C0.625 18.875 0.875 17.3125 2 16.5C3.125 15.6875 4.6875 15.9375 5.5 17.0625L7.5 19.75V5C7.5 3.6875 8.5625 2.5625 9.9375 2.5625C11.3125 2.5 12.5 3.6875 12.5 5.0625V16H13V2.5C13 1.1875 14.0625 0.0625 15.4375 0.0625C16.8125 0 18 1.1875 18 2.5625V16H18.5V4.9375C18.5 3.625 19.5625 2.5 20.9375 2.5C22.3125 2.4375 23.5 3.625 23.5 5V16H24V10.5625C24 9.1875 25.125 8 26.5 8.0625Z"
            fill="var(--U-colors-Blue)"
          />
        </svg>
        <span>{{ "LEAGUE_PARTICIPANT_REPORT" | transloco }}</span>
      </a>

      <a class="action-item" clickStopPropagation *ngIf="isNASKAAvailable">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.125 8.375C21.1875 6.5625 18.625 5.5 15.9375 5.5C11.125 5.5625 6.9375 8.875 5.8125 13.4375C5.6875 13.8125 5.375 14 5.0625 14H1.5C1 14 0.625 13.625 0.75 13.125C2.0625 5.9375 8.375 0.5 16 0.5C20.125 0.5 23.875 2.1875 26.6875 4.8125L28.9375 2.5625C29.875 1.625 31.5 2.3125 31.5 3.625V12C31.5 12.875 30.8125 13.5 30 13.5H21.5625C20.25 13.5 19.5625 11.9375 20.5 11L23.125 8.375ZM2 18.5H10.375C11.6875 18.5 12.375 20.125 11.4375 21.0625L8.8125 23.6875C10.75 25.5 13.3125 26.5625 16 26.5625C20.8125 26.5 25 23.1875 26.125 18.625C26.25 18.25 26.5625 18.0625 26.875 18.0625H30.4375C30.9375 18.0625 31.3125 18.4375 31.1875 18.9375C29.875 26.125 23.5625 31.5 16 31.5C11.8125 31.5 8.0625 29.875 5.25 27.25L3 29.5C2.0625 30.4375 0.5 29.75 0.5 28.4375V20C0.5 19.1875 1.125 18.5 2 18.5Z"
            fill="var(--U-colors-Blue)"
          />
        </svg>
        <span>{{ "RELOAD_NASKA_RATINGS" | transloco }}</span>
      </a>
    </div>
  </div>
</div>
