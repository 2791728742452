<div class="container">
  <div class="top-section">
    <div class="left-section">
      <div class="display-label w-100">
        <span class="label">League</span>
        <span class="input-like">AAU</span>
      </div>
      <div class="display-label max-w-170">
        <span class="label">Role</span>
        <span class="input-like">League owner</span>
      </div>
    </div>
  </div>

  <div class="main-section">
    <span class="title">{{ "WHITE_LABEL_SETTINGS" | transloco }}</span>

    <div class="section">
      <div class="description">
        <span class="descr-title">
          {{ "WHITE_LABEL_COLORS" | transloco }}
        </span>
        <span class="descr-subtitle">
          {{ "WHITE_LABEL_COLORS_DESCRIPTION" | transloco }}
        </span>
      </div>
      <div class="color-container" *ngFor="let group of colorGroups">
        <label class="radio-label">
          <input
            type="radio"
            name="colorGroup"
            [value]="group.name"
            [(ngModel)]="selectedColorGroup"
            (ngModelChange)="onColorGroupChange($event)"
          />
          {{ group.name }}
        </label>
        <div
          *ngFor="let color of group.colors"
          [ngStyle]="{ 'background-color': color }"
          class="color-box"
        ></div>
      </div>
    </div>
    <div class="section">
      <div class="description">
        <span class="descr-title">
          {{ "WHITE_LABEL_RADIUS" | transloco }}
        </span>
        <span class="descr-subtitle">
          {{ "WHITE_LABEL_RADIUS_DESCRIPTION" | transloco }}
        </span>
      </div>
      <div class="color-container" *ngFor="let group of cornerRadiusGroups">
        <label class="radio-label">
          <input
            type="radio"
            name="cornerGroup"
            [value]="group.radio"
            [(ngModel)]="selectedCornerGroup"
            (ngModelChange)="onCornerGroupChange($event)"
          />
          {{ group.name }}
        </label>
        <div
          *ngFor="let sample of group.samples"
          [ngStyle]="{
            'border-radius': group.radio,
            'background-color': sample.color,
            color:
              sample.color === 'transparent' ? sample.borderColor : 'white',
            border: '1px solid ' + (sample.borderColor || sample.color)
          }"
          class="sample"
        >
          {{ sample.label }}
        </div>
      </div>
    </div>
  </div>
</div>
