import { CommonModule, NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { LocalizationModule } from "src/app/components/localization";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { LeftPanelComponent } from "src/app/components/left-panel/left-panel.component";
import { DashboardComponent } from "../dashboard/dashboard.component";

@Component({
  selector: "app-white-label-settings",
  templateUrl: "./white-label-settings.component.html",
  styleUrls: ["./white-label-settings.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    LocalizationModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgSelectModule,
    DashboardComponent,
    LeftPanelComponent,
  ],
})
export class WhiteLabelSettingsComponent {
  selectedColorGroup = "AAU";
  colorGroups = [
    { name: "Default", colors: ["#01A1DA", "#CDD620", "#E15F5F"] },
    {
      name: "AAU",
      colors: ["#0E2A8D", "#3E8F3E", "#D10D14"],
    },
  ];

  cornerRadiusGroups = [
    {
      name: "0  px",
      radio: "0px",
      samples: [
        {
          label: "Corner radius of 0 px",
          color: "transparent",
          borderColor: "#A1A1A1",
        },
        { label: "Sample", color: "var(--customizable-primary-color)" },
        { label: "Sample", color: "var(--customizable-success-color)" },
        { label: "Sample", color: "var(--customizable-error-color)" },
      ],
    },
    {
      name: "4  px",
      radio: "4px",
      samples: [
        {
          label: "Corner radius of 4 px",
          color: "transparent",
          borderColor: "#A1A1A1",
        },
        { label: "Sample", color: "var(--customizable-primary-color)" },
        { label: "Sample", color: "var(--customizable-success-color)" },
        { label: "Sample", color: "var(--customizable-error-color)" },
      ],
    },
    {
      name: "8  px",
      radio: "8px",
      samples: [
        {
          label: "Corner radius of 8 px",
          color: "transparent",
          borderColor: "#A1A1A1",
        },
        { label: "Sample", color: "var(--customizable-primary-color)" },
        { label: "Sample", color: "var(--customizable-success-color)" },
        { label: "Sample", color: "var(--customizable-error-color)" },
      ],
    },
    {
      name: "12  px",
      radio: "12px",
      samples: [
        {
          label: "Corner radius of 12 px",
          color: "transparent",
          borderColor: "#A1A1A1",
        },
        { label: "Sample", color: "var(--customizable-primary-color)" },
        { label: "Sample", color: "var(--customizable-success-color)" },
        { label: "Sample", color: "var(--customizable-error-color)" },
      ],
    },
    {
      name: "16  px",
      radio: "16px",
      samples: [
        {
          label: "Corner radius of 16 px",
          color: "transparent",
          borderColor: "#A1A1A1",
        },
        { label: "Sample", color: "var(--customizable-primary-color)" },
        { label: "Sample", color: "var(--customizable-success-color)" },
        { label: "Sample", color: "var(--customizable-error-color)" },
      ],
    },
  ];
  selectedCornerGroup = "0px";

  constructor() {}

  onColorGroupChange(newValue: string) {
    const selectedColor = this.colorGroups.find((x) => x.name === newValue);
    if (!selectedColor) {
      return;
    }
    document.documentElement.style.setProperty(
      `--customizable-primary-color`,
      selectedColor.colors[0]
    );
    document.documentElement.style.setProperty(
      `--customizable-success-color`,
      selectedColor.colors[1]
    );
    document.documentElement.style.setProperty(
      `--customizable-error-color`,
      selectedColor.colors[2]
    );
  }

  onCornerGroupChange(newValue: string) {}
}
