<div class="step-container" *ngIf="activeStep$ === ForgotPasswordSteps.step1">
  <button class="close-btn" (click)="closeDialog()">
    <i class="fas fa-times close-button"></i>
  </button>

  <div class="icon-bg">
    <div class="icon-key"></div>
  </div>

  <div class="text700" style="margin-top: 15px">
    {{ "FORGOT_PASSWORD" | transloco }}?
  </div>
  <div style="margin-top: 7px; width: 300px">
    <div class="text400">{{ "ENTER_EMAIL_ADDRESS" | transloco }}</div>
    <div class="textsmall">{{ "WE_SEND_YOU_LINK" | transloco }}</div>
  </div>

  <div class="form-fields">
    <form
      [formGroup]="usernameFormGroup"
      #usernameForm="ngForm"
      (ngSubmit)="onUsernameFormSubmit()"
    >
      <div class="fp-field-item" style="margin-top: 15px">
        <mat-form-field>
          <mat-label>{{ "EMAIL" | transloco }}</mat-label>
          <input
            matInput
            required
            [placeholder]="'EMAIL' | transloco"
            [formControl]="usernameControl"
          />
        </mat-form-field>
        <mat-error
          class="form-top-margin"
          *ngIf="usernameForm.submitted && usernameControl.invalid"
          >{{ "EMAIL_NOT_CORRECT" | transloco }}</mat-error
        >
      </div>

      <div class="fp-field-item">
        <button
          class="reset-password-button"
          style="margin-top: 15px"
          type="submit"
          [disabled]="!usernameFormGroup.valid"
        >
          {{ "RESET_PASSWORD" | transloco }}
        </button>
      </div>
    </form>
  </div>

  <div style="margin-top: 25px">
    <a class="fp-link" (click)="displaySignInForm()">{{
      "BACK_TO_SIGN_IN" | transloco
    }}</a>
  </div>
</div>

<div class="step-container" *ngIf="activeStep$ === ForgotPasswordSteps.step2">
  <button class="close-btn" (click)="closeDialog()">
    <i class="fas fa-times close-button"></i>
  </button>

  <div class="icon-bg">
    <div class="icon-envelop"></div>
  </div>

  <div class="text700" style="margin-top: 15px">
    {{ "CHECK_EMAIL_FOR_PASSWORD_RESET_INSTRUCTIONS" | transloco }}
  </div>
  <div class="text400" style="margin-top: 25px">{{ username }}</div>

  <div class="text400" style="margin-top: 30px; width: 310px">
    {{ "DID_NOT_RECEIVE_EMAIL" | transloco }}
    <span style="color: #bab9ba">{{ timerStr }}</span>
    <a
      *ngIf="timerIsOver"
      class="fp-link"
      (click)="setStep(ForgotPasswordSteps.step1)"
      >{{ "TRY_AGAIN" | transloco }}</a
    >
  </div>

  <div style="margin-top: 25px">
    <a class="fp-link" (click)="displaySignInForm()">{{
      "BACK_TO_SIGN_IN" | transloco
    }}</a>
  </div>
</div>

<div class="step-container" *ngIf="activeStep$ === ForgotPasswordSteps.step3">
  <button class="close-btn" (click)="closeDialog()">
    <i class="fas fa-times close-button"></i>
  </button>

  <div class="icon-bg">
    <div class="icon-key"></div>
  </div>

  <div class="text700" style="margin-top: 15px">
    {{ "CREATE_NEW_PASSWORD" | transloco }}
  </div>
  <div class="text400" style="margin-top: 7px; width: 240px">
    {{ "SECURE_ACCOUNT" | transloco }}
  </div>

  <div style="width: 308px">
    <form
      [formGroup]="passwordFormGroup"
      #passwordForm="ngForm"
      (ngSubmit)="onUpdatePasswordFormSubmit()"
    >
      <div class="fp-field-item" style="margin-top: 15px">
        <mat-form-field>
          <mat-label>{{ "NEW_PASSWORD" | transloco }}</mat-label>
          <input
            matInput
            required
            [type]="hidePassword ? 'password' : 'text'"
            [placeholder]="'NEW_PASSWORD' | transloco"
            [formControl]="passwordControl"
          />
          <mat-icon
            matSuffix
            class="hide-password-button"
            (click)="hidePassword = !hidePassword"
            >{{ hidePassword ? "visibility_off" : "visibility" }}</mat-icon
          >
        </mat-form-field>
        <mat-error
          class="form-top-margin"
          *ngIf="passwordForm.submitted && passwordControl.invalid"
          >{{ "ENTER_A_VALID_PASSWORD" | transloco }}</mat-error
        >
      </div>

      <div class="fp-field-item">
        <mat-form-field>
          <mat-label>{{ "CONFIRM_PASSWORD" | transloco }}</mat-label>
          <input
            matInput
            required
            [type]="hideConfirmPassword ? 'password' : 'text'"
            [placeholder]="'CONFIRM_PASSWORD' | transloco"
            [formControl]="confirmPasswordControl"
          />
          <mat-icon
            matSuffix
            class="hide-password-button"
            (click)="hideConfirmPassword = !hideConfirmPassword"
            >{{
              hideConfirmPassword ? "visibility_off" : "visibility"
            }}</mat-icon
          >
        </mat-form-field>
        <!--<mat-error class="form-top-margin" *ngIf="passwordForm.submitted && confirmPasswordControl.invalid">{{ 'ENTER_A_VALID_PASSWORD' | transloco }}</mat-error>-->
        <mat-hint
          ><div class="password-hint" style="margin-top: -17px">
            {{ "PASSWORD_HINT" | transloco }}
          </div></mat-hint
        >
      </div>

      <div class="fp-field-item">
        <button
          class="update-password-button"
          style="margin-top: 25px"
          type="submit"
          [disabled]="
            !passwordFormGroup.valid ||
            passwordControl.value !== confirmPasswordControl.value
          "
        >
          {{ "UPDATE_PASSWORD" | transloco }}
        </button>
      </div>
    </form>
  </div>

  <div style="margin-top: 25px">
    <a class="fp-link" (click)="displaySignInForm()">{{
      "BACK_TO_SIGN_IN" | transloco
    }}</a>
  </div>
</div>

<div class="step-container" *ngIf="activeStep$ === ForgotPasswordSteps.step4">
  <button class="close-btn" (click)="closeDialog()">
    <i class="fas fa-times close-button"></i>
  </button>
  <div class="icon-bg">
    <div class="icon-checkmark"></div>
  </div>

  <div class="text700" style="margin-top: 15px">
    {{ "PASSWORD_RESET" | transloco }}
  </div>
  <div class="text400" style="margin-top: 7px">
    {{ "PASSWORD_UPDATED" | transloco }}
  </div>

  <div style="margin-top: 25px">
    <a class="fp-link" (click)="displaySignInForm()">{{
      "BACK_TO_SIGN_IN" | transloco
    }}</a>
  </div>
</div>
