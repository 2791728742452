import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { LocalizationModule } from "src/app/components/localization";
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Select, Store } from "@ngxs/store";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { UserState } from "src/app/store/user/user.store";
import { firstValueFrom, Observable, Subscription } from "rxjs";
import { User } from "src/app/shared/models/user.model";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { ApiService } from "src/app/shared/constants/api.service";
import { CustomMaterialInputComponent } from "src/app/components/custom-material-input/custom-material-input.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrService } from "ngx-toastr";
import { SafePipe } from "../../shared/pipes/safe.pipe";
import { getAge, getName } from "src/app/utils/membership-utils";

@Component({
  selector: "app-roster-orders",
  templateUrl: "./roster-orders.component.html",
  standalone: true,
  imports: [
    LocalizationModule,
    NgFor,
    NgIf,
    NgClass,
    SafePipe,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    CommonModule,
    CustomMaterialInputComponent,
    NgxPaginationModule,
  ],
  styleUrls: ["./roster-orders.component.scss"],
})
export class RosterOrdersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  user: User | undefined | null;

  subscriptions: Subscription[] = [];
  athletes: any;
  pageSize = 1000;
  pageIndex = 0;
  membersAmount!: number;
  public membersData: any;
  selectedCount: number = 0;
  eventId: number = 0;

  agreed = false;

  signatureForm!: FormGroup;
  _orderItemList: any;
  submitted = false;
  orders: any; // TODO: here you will have the orders or null if are not paid orders

  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.signatureForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
    });
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.authUser$.subscribe(async (user: any) => {
        if (user) {
          this.user = user;

          // const isAdmin = this.store.selectSnapshot(UserState.isAdmin);
          // if (!isAdmin) {
          //   this.router.navigateByUrl("/");
          // }
        } else {
          // this.router.navigateByUrl("/");
        }
      })
    );
  }

  ngOnInit(): void {
    this.eventId = +this.route.snapshot.params["eventId"];

    this.subscriptions.push(
      this.api.paidOrders$.subscribe((orders) => {
        this.orders = orders;
      })
    );
    if (!this.eventId) {
      // TODO: shoudl I go back?
    }
    // this.filterMembers(this.searchString);
    // this.fetchPaidOrders();
    // this.fetchActiveOrders();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      try {
        s.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    }
  }

  expand(order: any) {
    if (order.expanded) {
      order.expanded = false;
    } else {
      order.expanded = true;
    }
  }

  goToMembershipsStep() {
    this.router.navigateByUrl(`roster/${this.eventId}/shopping-cart`);
  }

  getName(athlete: any): string {
    return getName(athlete);
  }

  getAge(athlete: any): number | null {
    return getAge(athlete);
  }


  isAdult(birthDate: any): boolean {
    return getAge({ birthDate })! >= 18;
  }

  getCoverageType(extended: boolean) {
    if (extended) {
      return "ROSTER_EXTENDED_COVERAGE";
    } else {
      return "ROSTER_STANDARD_COVERAGE";
    }
  }

  getHourAndMinutesAMPM(dateString: string): string {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12 || 12;

    return `${hours}:${minutes} ${ampm}`;
  }

  capitalizeFirstLetter(str: string): string {
    if (!str) return ""; 
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}
