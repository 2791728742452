import { Component, Input } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";

import {
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-custom-searchable-select",
  templateUrl: "./custom-searchable-select.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    NgFor,
    MatListModule,
    MatFormFieldModule,
    NgIf,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatInputModule,
    LocalizationModule,
  ],
  styleUrls: ["./custom-searchable-select.component.scss"],
})
export class CustomSearchableSelectComponent {
  @Input() label?: string;
  @Input() error?: boolean;
  @Input() value?: string | { iconURL: string; text: string };
  @Input() submitted?: boolean;
  @Input() items!: string[] | any[];
  @Input() filteredItems!: string[] | any[];
  @Input() selectedItem: string | any | null = null;

  constructor() {}

  @ViewChild("searchInput") searchInput!: ElementRef<HTMLInputElement>;

  dropdownOpen = false;
  searchTerm = "";
  activeIndex: number = -1;

  @Output() filterChanged = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter<any>();

  @ViewChild("searchDopDownList", { static: false }) list!: ElementRef;

  ngOnInit(): void {
    this.filteredItems = [...this.items];
  }

  @HostListener("document:click", ["$event"])
  onClickOutside(event: MouseEvent) {
    const clickedInside = this.list?.nativeElement.contains(event.target);
    if (!clickedInside && this.dropdownOpen) {
      this.toggleDropdown();
    }
  }

  focus(ev: any) {
    if (!this.dropdownOpen) {
      this.dropdownOpen = true;
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 50);
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    if (this.dropdownOpen) {
      this.updateSearchTerm(this.selectedItem);
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 0);
    }
  }

  filter() {
    const searchTermLower = this.searchTerm.toLowerCase().trim();
    this.activeIndex = -1;
    this.filteredItems = this.items.filter((x) => {
      let searchItem = "";
      if (x.name) {
        searchItem += x.name;
      }
      if (x.phonePrefix) {
        searchItem += x.phonePrefix;
      }

      if (x.membershipId && x.zipCode) {
        searchItem += x.membershipId + " " + x.zipCode;
      }
      if (searchItem === "") {
        searchItem = x;
      }
      return searchItem.toLocaleLowerCase().includes(searchTermLower);
    });
    this.filterChanged.emit(searchTermLower);
  }

  handleKeydown(event: KeyboardEvent) {
    if (!this.filteredItems || this.filteredItems.length === 0) {
      return;
    }

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        if (this.activeIndex < this.filteredItems.length - 1) {
          this.activeIndex++;
        } else {
          this.activeIndex = 0;
        }
        break;

      case "ArrowUp":
        event.preventDefault();
        if (this.activeIndex > 0) {
          this.activeIndex--;
        } else {
          this.activeIndex = this.filteredItems.length - 1;
        }
        break;

      case "Enter":
        event.preventDefault();
        if (
          this.activeIndex >= 0 &&
          this.activeIndex < this.filteredItems.length
        ) {
          this.selectItem(this.filteredItems[this.activeIndex]);
        }
        break;

      case "Escape":
        this.dropdownOpen = false;
        break;
    }
  }

  selectItem(item: any) {
    this.selectedItem = item;
    this.dropdownOpen = false;
    this.updateSearchTerm(item);
    this.itemSelected.emit(item.id ? item.id : item); // Emit selected item
  }

  updateSearchTerm(item: any) {
    if (!item) {
      this.searchTerm = "";
    } else {
      this.searchTerm =
        item.membershipId && item.name && item.zipCode
          ? item.membershipId + " " + item.name + " " + item.zipCode
          : item.imageUrl
          ? item.phonePrefix
          : item; // Clear search after selection
    }
  }
}
