import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {DashboardActionModel} from "../models/dashboard-action.model";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  public eventCount$ = new BehaviorSubject({
    management: 0,
    reports: 0,
    settings: 0,
  });
  private adminRowAccountActions: DashboardActionModel[] = [];
  private adminRowFinancesActions: DashboardActionModel[] = [];
  private eventViewActions: DashboardActionModel[] = [];

  private accountHoldersAction: DashboardActionModel[] = [];
  private leagueActions: DashboardActionModel[] = [];

  public isPublicTimeTableEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPublicTimeTableMenuClick$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isPublicDrawsEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPublicDrawsEnabled$MenuClick$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public deleteAllScoresMenuClick$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  changePublicTimeTable(isPublicTimeTable: boolean) {
    this.isPublicTimeTableEnabled$.next(isPublicTimeTable)
  }
  changePublicDraws(isPublicDraws: boolean) {
    this.isPublicDrawsEnabled$.next(isPublicDraws)
  }
  setAdminRowAccountActions(actions:DashboardActionModel[]){
    this.adminRowAccountActions= actions
  }
  getAdminRowAccountActions(){
    return this.adminRowAccountActions
  }

  setAdminRowFinancesActions(actions:DashboardActionModel[]){
    this.adminRowFinancesActions= actions
  }
  getAdminRowFinancesActions(){
    return this.adminRowFinancesActions
  }


  getEventViewActions(): DashboardActionModel[] {
    return this.eventViewActions;
  }
  setEventViewActions(value: DashboardActionModel[]) {
    this.eventViewActions = value;
  }


  getAccountHoldersAction(): DashboardActionModel[] {
    return this.accountHoldersAction;
  }

  setAccountHoldersAction(value: DashboardActionModel[]) {
    this.accountHoldersAction = value;
  }

  getLeagueActions(): DashboardActionModel[] {
    return this.leagueActions;
  }

  setLeagueActions(value: DashboardActionModel[]) {
    this.leagueActions = value;
  }
}
