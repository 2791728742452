export function getName(athlete: any) {
  let name = "";
  if (athlete.firstName) {
    name += athlete.firstName;
  }
  if (athlete.middleName) {
    if (name) {
      name += " ";
    }
    name += athlete.middleName;
  }
  if (athlete.lastName) {
    if (name) {
      name += " ";
    }
    name += athlete.lastName;
  }
  return name;
}

export function getAge(athlete: any): number | null {
  if (athlete?.birthDate) {
    const birthDate = new Date(athlete.birthDate);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  }
  return null;
}
