import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { EventsService } from "../constants/events.service";
import { FilterType } from "src/app/components/filter/filter.component";
import { HomeBannerService } from "./home-banner.service";
import { EventsDateFilter } from "../constants/events-date-filter.enum";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  filteredSports$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  topSports$: BehaviorSubject<any> = new BehaviorSubject(null);
  loadingSports$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  filteredLeagues$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  topLeagues$: BehaviorSubject<any> = new BehaviorSubject(null);
  loadingLeagues$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  filteredCountries$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  topCountries$: BehaviorSubject<any> = new BehaviorSubject(null);
  loadingCountries$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  filters$ = new BehaviorSubject({
    sportIds: [],
    leagueIds: [],
    countryIds: [],
  });

  selectedItems$: BehaviorSubject<any> = new BehaviorSubject({
    sports: [],
    leagues: [],
    countries: [],
  });

  search$: BehaviorSubject<string> = new BehaviorSubject('');

  filterDates$: BehaviorSubject<any> = new BehaviorSubject(null);

  filteredPeriodEvents$: BehaviorSubject<any> = new BehaviorSubject(EventsDateFilter.Upcoming);

  constructor(
    private eventsService: EventsService,
    private homeBanner: HomeBannerService
  ) {
    this.getTopSportsList();
    this.getTopLeaguesList();
    this.getTopCountriesList();
  }

  public getTopSportsList() {
    this.loadingSports$.next(true);
    this.eventsService.getTopSportsList().subscribe((sports: any) => {
      if (sports) {
        const topSports: any[] = [];
        sports.map((sport: any) => {
          topSports.push({
            id: sport.id,
            name: sport.name,
            url: sport.logoUrl,
          });
        });

        this.topSports$.next(topSports);
        this.loadingSports$.next(false);
      }
    });
  }

  public getTopLeaguesList() {
    this.loadingLeagues$.next(true);
    this.eventsService.getTopLeaguesList().subscribe((leagues: any) => {
      if (leagues) {
        const topLeagues: any[] = [];
        leagues.map((league: any) => {
          topLeagues.push({
            id: league.id,
            name: league.name,
            url: league.logoUrl,
          });
        });

        this.topLeagues$.next(topLeagues);
        this.loadingLeagues$.next(false);
      }
    });
  }

  public getTopCountriesList() {
    this.loadingCountries$.next(true);
    this.eventsService.getTopCountriesList().subscribe((countries: any) => {
      if (countries) {
        const topCountries: any[] = [];
        countries.map((country: any) => {
          topCountries.push({
            id: country.id,
            name: country.name,
            code: country.code,
            url: country.code
              ? `https://cdn.uventex.com/events/images/flags/${country.code}.png`
              : null,
          });
        });

        this.topCountries$.next(topCountries);
        this.loadingCountries$.next(false);
      }
    });
  }

  filterItemSelected(items: any, type: FilterType) {
    this.homeBanner.showJoinBanner$.next(false);

    const filters = this.filters$.getValue();
    const selectedItems = this.selectedItems$.getValue();

    const ids =
      items && items.length > 0 ? items.map((item: any) => item.id) : [];

    const newSelectedItems = {
      sports: selectedItems ? selectedItems.sports : [],
      leagues: selectedItems ? selectedItems.leagues : [],
      countries: selectedItems ? selectedItems.countries : [],
    };

    const newFiltersIds = {
      sportIds: filters ? filters.sportIds : [],
      leagueIds: filters ? filters.leagueIds : [],
      countryIds: filters ? filters.countryIds : [],
    };

    if (type === FilterType.sports) {
      newFiltersIds.sportIds = ids;
      newSelectedItems.sports = items;
    } else if (type === FilterType.leagues) {
      newFiltersIds.leagueIds = ids;
      newSelectedItems.leagues = items;
    } else if (type === FilterType.countries) {
      newFiltersIds.countryIds = ids;
      newSelectedItems.countries = items;
    }

    this.filters$.next(newFiltersIds);
    this.selectedItems$.next(newSelectedItems);
  }

  onSearchChanged(search: string, type: FilterType) {
    const clearFilter = !(search.length > 0);

    this.homeBanner.showJoinBanner$.next(false);

    if (type === FilterType.sports) {
      if (clearFilter) {
        this.filteredSports$.next([]);
      } else {
        this.eventsService
          .getFilteredSportsList(search)
          .subscribe((response: any) => {
            const sports: any[] = [];
            if (response?.items) {
              const items = response.items;

              items.map((sport: any) => {
                sports.push({
                  id: sport.id,
                  name: sport.name,
                  url: sport.logoUrl,
                });
              });
            }

            this.filteredSports$.next(sports);
          });
      }
    } else if (type === FilterType.leagues) {
      if (clearFilter) {
        this.filteredLeagues$.next([]);
      } else {
        this.eventsService
          .getFilteredLeaguesList(search)
          .subscribe((response: any) => {
            const leagues: any[] = [];
            if (response?.items) {
              const items = response.items;

              items.map((league: any) => {
                leagues.push({
                  id: league.id,
                  name: league.name,
                  url: league.logoUrl,
                });
              });
            }

            this.filteredLeagues$.next(leagues);
          });
      }
    } else if (type === FilterType.countries) {
      if (clearFilter) {
        this.filteredCountries$.next([]);
      } else {
        this.eventsService
          .getFilteredCountriesList(search)
          .subscribe((response: any) => {
            const countries: any[] = [];
            if (response?.items) {
              const items = response.items;

              items.map((country: any) => {
                countries.push({
                  id: country.id,
                  name: country.name,
                  code: country.code,
                  url: country.code
                    ? `https://cdn.uventex.com/events/images/flags/${country.code}.png`
                    : null,
                });
              });
            }

            this.filteredCountries$.next(countries);
          });
      }
    }
  }
}
