<div class="desktop" *ngIf="!isDiffUrl">
  <div class="search-field">
    <input #desktopSearchField [@showHideSearch]="isOpen ? 'open' : 'closed'" type="text"
      placeholder="{{'SEARCH' | transloco}}" class="search-input" (keydown)="onEnterPress($event)">
      <mat-icon aria-hidden="false" fontIcon="clear" class="close" [ngClass]="{'visible': isOpen}" (click)="toggleDesktopSearch()">
      </mat-icon>
    <mat-icon aria-hidden="false" fontIcon="search" class="search" (click)="toggleDesktopSearch()">
    </mat-icon>
  </div>
</div>
<!-- <div class="adaptive">
  <mat-icon aria-hidden="false" fontIcon="search" class="adaptive__search" (click)="toggleDesktopSearchAdaptive()">
  </mat-icon>
</div> -->