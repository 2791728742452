<div class="image-upload-container">
  <input
    type="file"
    (change)="fileChange($event)"
    accept="image/*"
    hidden
    #fileInput
  />
  <input
    type="file"
    (change)="fileChange($event)"
    accept="image/*"
    capture="user"
    multiple="false"
    hidden
    #cameraInput
  />

  <div *ngIf="showCameraModal" class="camera-modal">
    <div class="camera-preview">
      <video #videoElement class="video-feed"></video>
      <canvas #canvasElement hidden></canvas>
      <button type="button" class="capture-button" (click)="captureImage()">
        {{ "CAPTURE_PHOTO" | transloco }}
      </button>
      <button type="button" class="close-button" (click)="closeCamera()">
        x
      </button>
    </div>
  </div>
  <div class="image-preview-wrapper">
    <div
      class="image-container"
      (click)="!imageChangedEvent ? fileInput.click() : null"
    >
      <ng-container *ngIf="cropperEnabled && imageChangedEvent; else showImage">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1"
          [resizeToWidth]="200"
          [onlyScaleDown]="true"
          [containWithinAspectRatio]="true"
          [transform]="transform"
          format="png"
          (imageCropped)="imageCropped($event)"
          #cropper
        ></image-cropper>
      </ng-container>
      <ng-template #showImage>
        <img
          *ngIf="imageChangedEvent"
          [src]="croppedImage || 'assets/img/blank-profile.png'"
          alt="Image Preview"
          class="preview-image"
        />
      </ng-template>

      <div class="overlay-text" *ngIf="!imageChangedEvent">
        <img
          [src]="imageUrl ?? 'assets/img/blank-profile.png'"
          class="img-upload"
        />
      </div>
    </div>
    <div class="preview-container" *ngIf="croppedImage">
      <img [src]="croppedImage" alt="Cropped preview" class="preview-image" />
      <div class="preview-label">Preview</div>
    </div>
  </div>
  <div class="controls">
    <button type="button" (click)="fileInput.click()" class="upload-button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M1.5 8.35118C0.672656 8.35118 0 9.00569 0 9.81071V10.5405C0 11.3455 0.672656 12 1.5 12H10.5C11.3273 12 12 11.3455 12 10.5405V9.81071C12 9.00569 11.3273 8.35118 10.5 8.35118H7.33333C7.33333 8.35118 7.33333 9.40528 6 9.40528C4.66667 9.40528 4.66667 8.35118 4.66667 8.35118H1.5ZM10.125 10.7229C9.81328 10.7229 9.5625 10.4789 9.5625 10.1756C9.5625 9.87228 9.81328 9.62827 10.125 9.62827C10.4367 9.62827 10.6875 9.87228 10.6875 10.1756C10.6875 10.4789 10.4367 10.7229 10.125 10.7229Z"
          fill="white"
        />
        <path
          d="M6.00436 8.7566C6.41921 8.7566 6.75436 8.43049 6.75436 8.02684V2.49203L8.47233 4.16593C8.7653 4.45099 9.24108 4.45099 9.53405 4.16593C9.82702 3.88086 9.82702 3.41792 9.53405 3.13286L6.53405 0.213798C6.24108 -0.071266 5.7653 -0.071266 5.47233 0.213798L2.47233 3.13286C2.17936 3.41792 2.17936 3.88086 2.47233 4.16593C2.7653 4.45099 3.24108 4.45099 3.53405 4.16593L5.25436 2.49203V8.02684C5.25436 8.43049 5.58952 8.7566 6.00436 8.7566Z"
          fill="white"
        />
      </svg>
    </button>

    <button type="button" (click)="openCamera()" class="upload-button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
      >
        <path
          d="M8.10744 5.78167C8.72113 6.39536 8.72113 7.39032 8.10744 8.004C7.49376 8.61768 6.4988 8.61768 5.88512 8.004C5.27143 7.39032 5.27143 6.39536 5.88512 5.78167C6.4988 5.16799 7.49376 5.16799 8.10744 5.78167Z"
          fill="white"
        />
        <path
          d="M11.7106 2.57143H10.6248L9.83872 1H4.15342L3.36808 2.5722L2.28389 2.57413C1.41953 2.57568 0.715905 3.28006 0.715158 4.14479L0.710938 10.4282C0.710938 11.2949 1.4157 12 2.28237 12H11.7106C12.5772 12 13.282 11.2952 13.282 10.4286V4.14284C13.282 3.2762 12.5772 2.57143 11.7106 2.57143ZM6.99625 10.0357C5.2633 10.0357 3.85339 8.62579 3.85339 6.89284C3.85339 5.15989 5.2633 3.74997 6.99625 3.74997C8.72921 3.74997 10.1391 5.15989 10.1391 6.89284C10.1391 8.62579 8.72921 10.0357 6.99625 10.0357Z"
          fill="white"
        />
      </svg>
    </button>

    <button
      type="button"
      [ngClass]="croppedImage ? 'upload-button' : 'img-second-buton'"
      (click)="toggleCropper()"
    >
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4375 8.75C11.7422 8.75 12 9.00781 12 9.3125V10.4375C12 10.7656 11.7422 11 11.4375 11H10.5V11.9375C10.5 12.2656 10.2422 12.5 9.9375 12.5H8.8125C8.48438 12.5 8.25 12.2656 8.25 11.9375V4.25H4.5V2H9.75C10.1484 2 10.5 2.35156 10.5 2.75V8.75H11.4375ZM3.75 1.0625V8.75H7.5V11H2.25C1.82812 11 1.5 10.6719 1.5 10.25V4.25H0.5625C0.234375 4.25 0 4.01562 0 3.6875V2.5625C0 2.25781 0.234375 2 0.5625 2H1.5V1.0625C1.5 0.757812 1.73438 0.5 2.0625 0.5H3.1875C3.49219 0.5 3.75 0.757812 3.75 1.0625Z"
          fill="white"
        />
      </svg>
    </button>
    <button
      type="button"
      [ngClass]="croppedImage ? 'upload-button' : 'img-second-buton'"
      (click)="rotate(90)"
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons M 12x12 (LV)">
          <path
            id="Vector"
            d="M10.2312 1.76437C9.00892 0.542092 7.27673 -0.162779 5.37208 0.0321855C2.62008 0.309635 0.355497 2.54423 0.0480531 5.29622C-0.364372 8.93306 2.44761 12 5.99447 12C8.38653 12 10.4412 10.5978 11.401 8.58062C11.6409 8.07822 11.281 7.50082 10.7261 7.50082C10.4487 7.50082 10.1862 7.65079 10.0662 7.89825C9.21888 9.72042 7.18675 10.8752 4.96715 10.3803C3.30246 10.0129 1.9602 8.65561 1.60777 6.99091C0.977883 4.08145 3.18998 1.50192 5.99447 1.50192C7.23924 1.50192 8.34904 2.01932 9.15889 2.83667L8.0266 3.96897C7.55418 4.44138 7.88412 5.25123 8.5515 5.25123H11.2435C11.6559 5.25123 11.9934 4.91379 11.9934 4.50137V1.80936C11.9934 1.14198 11.1835 0.804544 10.7111 1.27696L10.2312 1.76437Z"
            fill="white"
          />
        </g>
      </svg>
    </button>
    <button
      (click)="cancelImage()"
      type="button"
      [ngClass]="croppedImage ? 'delete-button' : 'img-second-buton'"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M11.125 0.75C11.3125 0.75 11.5 0.9375 11.5 1.125V1.875C11.5 2.08594 11.3125 2.25 11.125 2.25H1.375C1.16406 2.25 1 2.08594 1 1.875V1.125C1 0.9375 1.16406 0.75 1.375 0.75H4.1875L4.39844 0.328125C4.49219 0.140625 4.67969 0 4.89062 0H7.58594C7.79688 0 7.98438 0.140625 8.07812 0.328125L8.3125 0.75H11.125ZM2.24219 10.9453L1.75 3H10.75L10.2344 10.9453C10.2109 11.5547 9.71875 12 9.10938 12H3.36719C2.75781 12 2.26562 11.5547 2.24219 10.9453Z"
          fill="white"
        />
      </svg>
    </button>
  </div>

  <div class="tips">
    <span>{{ "NEW_ATHLETE_IMG_TIPS" | transloco }}</span>
    <ul>
      <li>{{ "NEW_ATHLETE_IMG_TIP_1" | transloco }}</li>
      <li>{{ "NEW_ATHLETE_IMG_TIP_2" | transloco }}</li>
      <li>{{ "NEW_ATHLETE_IMG_TIP_3" | transloco }}</li>
      <li>{{ "NEW_ATHLETE_IMG_TIP_4" | transloco }}</li>
    </ul>
  </div>
</div>
