<div class="p-32">
  <div class="first-column">
    <img src="assets/img/ncsa-banner.jpeg" class="imgMobile" />

    <span class="title">{{ "NCSA_BANNER_TITLE" | transloco }}</span>
    <span class="sub-title">{{ "NCSA_BANNER_SUB_TITLE" | transloco }}</span>
    <div class="description">
      <span class="text-descr">{{
        "NCSA_BANNER_DESCRIPTION" | transloco
      }}</span>
      <span class="text-quote">"{{ "NCSA_BANNER_QUOTE" | transloco }}"</span>
      <span class="text-quote">{{
        "NCSA_BANNER_QUOTE_AUTHOR" | transloco
      }}</span>
    </div>

    <div class="radio">
      <span class="radio-item" (click)="select(true)">
        <svg
          class="img-rad"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <circle
            cx="10"
            cy="10"
            r="9"
            [attr.stroke]="
              yes ? 'var(--customizable-primary-color)' : '#9A9A9A'
            "
            stroke-width="2"
          />
          <circle
            *ngIf="yes"
            cx="10"
            cy="10"
            r="5"
            fill="var(--customizable-primary-color)"
          />
        </svg>
        {{ "NCSA_BANNER_LABEL_YES" | transloco }}</span
      >
      <span
        class="note"
        [innerHTML]="'NCSA_BANNER_LABEL_YES_NOTE' | transloco"
      ></span>
      <span class="radio-item" (click)="select(false)">
        <svg
          class="img-rad"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <circle
            cx="10"
            cy="10"
            r="9"
            [attr.stroke]="
              !yes ? 'var(--customizable-primary-color)' : '#9A9A9A'
            "
            stroke-width="2"
          />
          <circle
            *ngIf="!yes"
            cx="10"
            cy="10"
            r="5"
            fill="var(--customizable-primary-color)"
          />
        </svg>
        {{ "NCSA_BANNER_LABEL_NO" | transloco }}</span
      >
    </div>
  </div>
  <div class="second-column">
    <img src="assets/img/ncsa-banner.jpeg" />
  </div>
</div>
