export const IconUvFooter = `<svg xmlns="http://www.w3.org/2000/svg" width="171" height="50" viewBox="0 0 171 50" fill="none">
<path d="M65.6461 37.4009V29.7292C65.6461 27.1243 64.5409 26.0938 62.4758 26.0938H60.1198V37.5441C60.1198 40.4925 59.0436 41.8379 56.8622 41.8379C54.2735 41.8379 53.081 40.7501 53.081 37.4009V29.8437C53.081 27.1243 51.6849 26.0938 49.9106 26.0938H47.5547V37.5727C47.5547 42.8971 51.1904 46.3035 56.3386 46.3035C62.5339 46.3035 65.6461 42.3818 65.6461 37.4009Z" fill="white"/>
<path d="M83.6557 35.6261C84.0339 34.5384 84.412 33.3933 84.412 32.2483C84.412 30.9315 83.5685 30.1014 82.1724 30.1014H79.5255C78.9438 33.6223 77.5186 38.0879 76.5006 39.9486C75.7735 38.6032 74.3483 34.8819 73.8538 32.3628C73.6502 31.3609 72.5158 30.1014 70.7125 30.1014H67.833C68.5311 34.7101 70.7707 40.4925 72.923 43.7845C73.8538 45.2158 74.7264 46.0745 76.5588 46.0745H78.0422C79.9618 43.9849 82.6087 38.6318 83.6557 35.6261Z" fill="white"/>
<path d="M101.833 35.941C101.833 31.9334 98.5757 29.6147 94.3873 29.6147C89.4427 29.6147 86.156 33.5078 86.156 38.2883C86.156 42.6394 89.0646 46.3035 94.5909 46.3035C99.1574 46.3035 100.815 44.5001 100.815 42.4963V41.2368H100.612C99.71 41.8379 98.1103 42.2387 95.5507 42.2387C93.253 42.2387 91.9441 41.4944 91.6241 39.92H94.0092C99.4192 39.92 101.833 39.0898 101.833 35.941ZM96.7142 35.3399C96.7142 36.0269 96.2488 36.4277 93.6311 36.4277H91.4787C91.6241 34.9105 92.4386 33.6796 94.3873 33.6796C95.987 33.6796 96.7142 34.5384 96.7142 35.3399Z" fill="white"/>
<path d="M120.804 45.8455V37.2578C120.804 32.5918 118.506 29.6433 112.835 29.6433C107.745 29.6433 104.691 32.5918 104.691 37.1147V42.6394C104.691 44.9295 105.999 45.8455 108.355 45.8455H110.217V37.0288C110.217 35.1109 111.089 34.0517 112.689 34.0517C114.551 34.0517 115.278 34.9964 115.278 37.1147V42.6394C115.278 45.044 116.034 45.8455 118.477 45.8455H120.804Z" fill="white"/>
<path d="M124.271 30.7025V40.4639C124.271 44.7864 127.209 46.0745 129.943 46.0745C132.823 46.0745 134.044 44.8723 134.044 43.5555V41.4372H133.899C133.317 41.6662 132.706 41.7807 131.397 41.7807C130.321 41.7807 129.652 41.1509 129.652 40.0917V34.3952H131.63C132.881 34.3952 134.335 33.3647 134.335 32.0193V30.1014H129.652V26.2655H129.07C127.907 26.2655 126.918 26.7808 126.191 27.3247C125.115 28.1262 124.271 29.2712 124.271 30.7025Z" fill="white"/>
<path d="M151.817 35.941C151.817 31.9334 148.559 29.6147 144.371 29.6147C139.426 29.6147 136.139 33.5078 136.139 38.2883C136.139 42.6394 139.048 46.3035 144.574 46.3035C149.141 46.3035 150.799 44.5001 150.799 42.4963V41.2368H150.595C149.693 41.8379 148.094 42.2387 145.534 42.2387C143.236 42.2387 141.928 41.4944 141.608 39.92H143.993C149.403 39.92 151.817 39.0898 151.817 35.941ZM146.698 35.3399C146.698 36.0269 146.232 36.4277 143.615 36.4277H141.462C141.608 34.9105 142.422 33.6796 144.371 33.6796C145.97 33.6796 146.698 34.5384 146.698 35.3399Z" fill="white"/>
<path d="M166.832 45.9887C168.752 45.9887 170.002 45.0726 170.002 43.3837C168.403 42.9543 167.85 42.5536 166.105 40.149L164.68 38.1738C165.581 37.2005 166.454 36.3418 167.094 35.5403C168.257 34.109 168.752 32.9353 168.752 32.0765C168.752 30.9888 167.966 30.1014 166.541 30.1014H164.65C164.534 31.275 163.894 32.4487 161.887 34.5956C161.655 34.2807 161.189 33.6223 160.695 32.9353C160.171 32.2197 159.589 31.4754 159.211 31.1319C158.397 30.3876 157.641 30.1014 156.855 30.1014H153.889C153.947 31.3323 154.238 32.0193 154.703 32.7922C155.721 34.4238 156.914 35.8838 158.077 37.4296L157.088 38.4028C154.761 40.6929 153.976 42.2101 153.976 43.9849C153.976 45.0726 155.023 45.8455 156.157 45.8455H158.397C158.63 43.8703 159.357 42.6967 160.84 41.1223C161.276 41.7234 161.684 42.3532 162.12 42.9543C163.312 44.6146 164.534 45.9887 166.832 45.9887Z" fill="white"/>
<path d="M40.4425 25.8766C41.1873 25.8766 42.3328 25.8766 42.7257 25.8766C43.5277 36.4698 37.5451 46.9692 25.4043 49.8766C34.7996 45.6662 37.2833 38.1954 37.2833 30.0094C37.2833 27.6331 38.6342 25.8767 40.4425 25.8766Z" fill="#CDD620"/>
<path d="M18.8587 32.7454C16.413 28.3964 18.7594 24.1196 21.0587 22.5532C23.3581 20.9868 23.8856 18.8056 23.2498 15.395C21.9583 17.0805 21.3121 17.6217 19.4064 18.9444C13.2358 23.2273 10.7422 31.7695 17.15 37.1274C21.561 40.8157 30.8994 40.0028 32.8664 31.2187C30.6411 37.762 21.9698 38.2776 18.8587 32.7454Z" fill="#C2C2C2"/>
<path d="M15.004 38.2167C21.0878 44.5593 34.277 41.8256 35.1313 29.8489C36.9395 42.6781 24.4098 52.8075 12.4409 48.1707C-1.68607 42.698 -3.0969 23.9002 13.6291 10.2315C16.9803 7.49284 18.8983 4.01171 19.0917 0.598257C22.9516 5.61785 22.4013 12.9973 17.5869 17.5747C11.7057 23.1665 8.32794 31.2567 15.004 38.2167Z" fill="var(--U-colors-Blue)"/>
<path d="M31.0645 28.6659C31.2082 31.7287 28.8418 34.3281 25.779 34.4718C22.7162 34.6155 20.1168 32.2491 19.9731 29.1863C19.8294 26.1235 22.1958 23.5241 25.2586 23.3804C28.3214 23.2367 30.9208 25.6031 31.0645 28.6659Z" fill="#E82B36"/>
</svg>`