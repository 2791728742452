import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { LocalizationModule } from "src/app/components/localization";
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { BottomBannerComponent } from "src/app/components/bottom-banner/bottom-banner.component";
import { Select, Store } from "@ngxs/store";
import { EventsService } from "src/app/shared/constants/events.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { UserState } from "src/app/store/user/user.store";
import {
  elementAt,
  firstValueFrom,
  map,
  Observable,
  skip,
  Subscription,
} from "rxjs";
import { User } from "src/app/shared/models/user.model";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { RegistrationAthleteComponent } from "../registration-athlete/registration-athlete.component";
import { ApiService } from "src/app/shared/constants/api.service";
import { CustomMaterialInputComponent } from "src/app/components/custom-material-input/custom-material-input.component";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrService } from "ngx-toastr";
import { SafePipe } from "../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../shared/constants/safe-types.enum";
import { getAge, getName } from "src/app/utils/membership-utils";

@Component({
  selector: "app-roster-memberships",
  templateUrl: "./roster-memberships.component.html",
  standalone: true,
  imports: [
    LocalizationModule,
    NgFor,
    NgIf,
    NgClass,
    SafePipe,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    CommonModule,
    CustomMaterialInputComponent,
    NgxPaginationModule,
  ],
  styleUrls: ["./roster-memberships.component.scss"],
})
export class RosterMembershipsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  user: User | undefined | null;

  subscriptions: Subscription[] = [];
  athletes: any;
  pageSize = 1000;
  pageIndex = 0;
  membersAmount!: number;
  public membersData: any;
  selectedCount: number = 0;
  eventId: number = 0;
  standardAthleteList: any;
  extendedAthleteList: any;

  terms = [
    `Membership in the AAU is a privilege granted by the AAU. It is not a right. The AAU at its sole discretion reserves the right to accept or reject any applicant(s) for membership.<br><br>Membership in any category may be granted only after an application is submitted and approved. By submitting an application, the applicant agrees to comply with all the provisions of the <u>AAU Code, including its constitution, bylaws, policies, procedures, regulations, and rules*</u>.<br><br> I certify that I have the athlete's parent's or guardian's consent for the athlete to become an AAU Member.<br><br>* I accept all terms and conditions for this AAU membership application as laid out by the AAU code book (available here) and this application.<br><br>* I hereby certify that all information I have provided is accurate, my name (below) is correct, and I am authorized to apply for membership for the youths in this application.<br><br>* I understand that there are no refunds issued for cancellations.<br><br>Membership must always be paid and member number issued prior to participation in any activity (including practices and try-outs)`,
    `<img src='assets/icons/red-warn.svg' class='term-icon'> <span>Adult Athletes are NOT permitted to engage with youth athletes as coaches, officials, club organizers, event hosts or the like without a separate Non-Athlete membership.</span>`,
    `By entering my name below I hereby authorize AAU to create the requested youth memberships, accept and acknowledge all terms and conditions presented to me during the application process.`,
    `By entering my name below I hereby authorize AAU to perform a background screening for my adult membership, accept and acknowledge all terms and conditions presented to me during the application process.<br><br>NOTE: THIS MUST BE SIGNED BY THE PERSON APPLYING FOR MEMBERSHIP OR A PARENTALLY APPROVED REPRESENTATIVE FOR YOUTH APPLICANTS.<br><br>* I understand that Adult Athletes are NOT permitted to engage with youth athletes as coaches, officials, club organizers, event hosts or the like without a separate Non-Athlete membership.`,
  ];
  agreed = false;

  signatureForm!: FormGroup;
  _orderItemList: any;

  memberships = [
    {
      id: 1,
      title: "Extended benefit",
      titleNote: "(AB) Coverage",
      selectedAthletes: 0,
      note1:
        "This membership permits participation in non-AAU events. For team competitions, all members, coaches, and staff need AAU extended coverage. In individual events, each participant must have extended coverage and be supervised by a registered AAU coach.",
      note2: "*Please note that memberships are non-refundable once issued.",
      expanded: false,
      totalPrice: 0,
      name: "EXTENDED",
    },
    {
      id: 2,
      title: "Standard coverage",
      titleNote: "(AB) Coverage",
      selectedAthletes: 0,
      note1:
        "A standard coverage membership is required for athletes and team members to participate in licensed AAU events.",
      note2: "*Please note that memberships are non-refundable once issued.",
      expanded: false,
      totalPrice: 0,
      name: "STANDARD",
    },
  ];
  submitted = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.signatureForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
    });
  }

  updateForm(formInput: string, value: string) {
    const control = this.signatureForm.get(formInput);
    if (control) {
      control.setValue(value);
      control.markAsTouched();
    }
  }

  updateFormWithEvent(inputValue: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.updateForm(inputValue, inputElement.value);
  }

  selectAth(
    ath: any,
    membership: {
      id: number;
      title: string;
      titleNote: string;
      selectedAthletes: number;
      note1: string;
      note2: string;
      expanded: boolean;
      totalPrice: number;
      name: string;
    }
  ) {
    if (
      this.isAthleteInOrderWithOtherCoverageType(ath.personId, membership.name)
    ) {
      this.removeAthlete(ath).then(() => this.insertOrderItem(ath));
    } else {
      this.insertOrderItem(ath);
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.authUser$.subscribe(async (user: any) => {
        if (user) {
          this.user = user;
          this.getAllowedOrderItems();
          this.filterMembers("", this.pageIndex, this.pageSize).then(() => {
            this.getOrderItems(this.eventId);
          });

          // const isAdmin = this.store.selectSnapshot(UserState.isAdmin);
          // if (!isAdmin) {
          //   this.router.navigateByUrl("/");
          // }
        } else {
          // this.router.navigateByUrl("/");
        }
      })
    );
  }

  async removeAthlete(ath: any) {
    let orderItem = this._orderItemList.find(
      (x: any) => x.person.id === ath.personId
    );
    if (!orderItem) {
      console.error("No item found to remove");
      return;
    }

    try {
      this.orderItemList = await firstValueFrom(
        this.api.deleteOrderItem(
          this.eventId,
          orderItem.id,
          orderItem.person.id
        )
      );
    } catch (e) {
      console.error(e);
    }
  }

  async filterMembers(search: string, pageIndex: number, pageSize: number) {
    try {
      const data: any = await firstValueFrom(
        this.api.getPersons(search, pageIndex, pageSize)
      );
      this.membersData = data;
    } catch (e) {
      console.error(e);
    }
  }

  ngOnInit(): void {
    this.eventId = +this.route.snapshot.params["eventId"];
    if (!this.eventId) {
      // TODO: shoudl I go back?
    }
    // this.filterMembers(this.searchString);
    // this.fetchPaidOrders();
    // this.fetchActiveOrders();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      try {
        s.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async getAllowedOrderItems() {
    try {
      const data: any = await firstValueFrom(
        this.api.getAllowedOrderItems(this.eventId)
      );
      this.updateUI(data);
    } catch (e) {
      console.error(e);
    }
  }

  async getOrderItems(eventId: number) {
    try {
      this.orderItemList = await firstValueFrom(this.api.getOrder(eventId));
    } catch (e) {
      console.error(e);
    }
  }

  async insertOrderItem(allowedItem: {
    itemType: string;
    personId: number;
    coverage: string;
  }) {
    try {
      this.orderItemList = await firstValueFrom(
        this.api.insertOrderItem(this.eventId, allowedItem)
      );
    } catch (e) {
      console.error(e);
    }
  }

  set orderItemList(data: any) {
    if (data && data.orderItemList) {
      this._orderItemList = data.orderItemList;
      this.getAllowedOrderItems();
    } else {
      this._orderItemList = [];
      console.error(data);
    }
  }

  expand(athlete: any) {
    if (athlete.expanded) {
      athlete.expanded = false;
    } else {
      athlete.expanded = true;
    }
  }

  onEdit(athlete: any) {
    this.api.editingPerson = athlete;
    this.router.navigateByUrl(
      `roster/${this.eventId}/edit-athlete/${athlete.id}`
    );
  }

  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value).then(() => {
      this.toastr.success("Copied to clipboard!");
    });
  }

  addAthlete() {
    this.router.navigateByUrl(`roster/${this.eventId}/new-athlete`);
  }

  goToMembershipsStep() {
    this.router.navigateByUrl(`roster/${this.eventId}/shopping-cart`);
  }

  getName(athlete: any): string {
    return getName(athlete);
  }

  getAge(athlete: any): number | null {
    return getAge(athlete);
  }

  updateUI(data: any) {
    this.standardAthleteList = [];
    this.extendedAthleteList = [];
    this.memberships.forEach((m) => (m.selectedAthletes = m.totalPrice = 0));

    data.forEach((element: any) => {
      if (element.paid) return;

      const isExtended = element.coverage === "EXTENDED";
      const list = isExtended
        ? this.extendedAthleteList
        : this.standardAthleteList;
      const membership = this.memberships[isExtended ? 0 : 1];

      list.push(element);

      if (element.selected) {
        membership.selectedAthletes++;
        membership.totalPrice += element.price;
      }
    });
  }

  isAdult(birthDate: any): boolean {
    return getAge({ birthDate })! >= 18;
  }

  isAthleteInOrderWithOtherCoverageType(
    personId: number,
    membershipName: string
  ): boolean {
    return this._orderItemList.some(
      (x: any) =>
        x.person.id === personId && x.additionalFieldFirst !== membershipName
    );
  }
}


