import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule } from "@angular/forms";
import { IconArrowUp } from "src/assets/icons/arrow-up";
import { SafeTypes } from "../../shared/constants/safe-types.enum";
import { TranslocoModule } from "@ngneat/transloco";
import { FilterService } from "src/app/shared/services/filter.service";
import { Subscription } from "rxjs";

export enum FilterType {
  countries = "countries",
  leagues = "leagues",
  sports = "sports",
}

@Component({
  selector: "app-filter",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TranslocoModule,
  ],
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() title!: string;
  @Input() maxItems!: number;
  @Input() type!: FilterType;

  _values: any[] = [];

  get values(): any[] {
    return this._values;
  }

  loading: boolean = true;

  @Input() set values(values: any[]) {
    this._values = values;
    this.updateItems();
  }

  _filteredItems: any[] = [];

  get filteredItems(): any[] {
    return this._filteredItems;
  }

  @Input() set filteredItems(items: any[]) {
    if (this.items && this.items.length > 0) {
      const ids = this.items.map((item) => item.id);

      const filteredItems: any[] = [];
      if (items && items.length > 0) {
        items.map((item) => {
          if (!ids.includes(item.id)) {
            filteredItems.push(item);
          }
        });
      }

      this._filteredItems = filteredItems;
    } else {
      this._filteredItems = items;
    }
  }

  protected readonly FilterType = FilterType;

  private subscriptions: Subscription[] = [];

  public searchText!: string;
  public IconArrowUp = IconArrowUp;
  public SafeTypes = SafeTypes;

  public items: any[] = [];
  selectedItems: any[] = [];

  searchTimeout: any;

  constructor(private filterService: FilterService) {}
  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      try {
        s.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    }
    this.subscriptions = [];
  }

  public ngOnInit(): void {
    if (this.type === FilterType.sports) {
      this.subscriptions.push(
        this.filterService.topSports$.subscribe((values) => {
          if (values) {
            this.values = values;
          }
        })
      );
      this.subscriptions.push(
        this.filterService.filteredSports$.subscribe((values) => {
          this.filteredItems = values;
        })
      );

      this.subscriptions.push(
        this.filterService.loadingSports$.subscribe((loading) => {
          this.loading = loading;
        })
      );
    }

    if (this.type === FilterType.leagues) {
      this.subscriptions.push(
        this.filterService.topLeagues$.subscribe((values) => {
          if (values) {
            this.values = values;
          }
        })
      );

      this.subscriptions.push(
        this.filterService.filteredLeagues$.subscribe((values) => {
          this.filteredItems = values;
        })
      );
      this.subscriptions.push(
        this.filterService.loadingLeagues$.subscribe((loading) => {
          this.loading = loading;
        })
      );
    }

    if (this.type === FilterType.countries) {
      this.subscriptions.push(
        this.filterService.topCountries$.subscribe((values) => {
          if (values) {
            this.values = values;
          }
        })
      );
      this.subscriptions.push(
        this.filterService.filteredCountries$.subscribe((values) => {
          this.filteredItems = values;
        })
      );

      this.subscriptions.push(
        this.filterService.loadingCountries$.subscribe((loading) => {
          this.loading = loading;
        })
      );
    }

    this.filterService.selectedItems$.subscribe((v) => {
      if (this.type === FilterType.sports) {
        this.selectedItems = v.sports;
      } else if (this.type === FilterType.leagues) {
        this.selectedItems = v.leagues;
      } else if (this.type === FilterType.countries) {
        this.selectedItems = v.countries;
      }
    });
  }

  onSearchChange(search: any) {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.items = this.filterItemsByName(search);
      this.filterService.onSearchChanged(search, this.type);
    }, 500);
  }

  updateItems() {
    this.items = this.filterItemsByName(this.searchText);
  }

  filterItemsByName(search: string) {
    const items = [...this._values];
    if (!(search && search.length > 0)) {
      return items;
    }

    return items.filter((item: any) =>
      item.name ? item.name.toLowerCase().trim().includes(search) : false
    );
  }

  selectItem(item: any) {
    const selectedItems = [...this.selectedItems];
    if (!selectedItems.find((x) => x.id === item.id)) {
      selectedItems.push(item);
      this.selectedItems = selectedItems;
      this.filterService.filterItemSelected(this.selectedItems, this.type);
    }
  }

  deselectItem(item: any) {
    const selectedItems = [...this.selectedItems];
    const index = selectedItems.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      selectedItems.splice(index, 1);
      this.selectedItems = selectedItems;
      this.filterService.filterItemSelected(this.selectedItems, this.type);
    }
  }

  isItemSelected(item: any) {
    return this.selectedItems.find((x) => x.id === item.id);
  }

  clearFilters() {
    this.searchText = "";
    this.onSearchChange("");
    this.selectedItems = [];
    this.filterService.filterItemSelected([], this.type);
  }

  isFilterVisible() {
    return this.selectedItems && this.selectedItems.length > 0;
  }
}
