import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgFor, NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import { MatIconModule } from "@angular/material/icon";
import { FilterService } from "src/app/shared/services/filter.service";
import { Subscription } from "rxjs";
import { FilterType } from "src/app/components/filter/filter.component";

@Component({
  selector: "app-mobile-filters-dialog",
  templateUrl: "./mobile-filters-dialog.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    NgIf,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgFor,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    LocalizationModule,
  ],
  styleUrls: ["./mobile-filters-dialog.component.scss"],
})
export class MobileFiltersDialogComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<void>();

  subscriptions: Subscription[] = [];

  types: any = [
    {
      placeholder: "Search sport",
      query: "",
      items: [],
      isDropdownVisible: false,
      selectedItems: [],
      type: FilterType.sports,
    },
    {
      placeholder: "Search league",
      query: "",
      items: [],
      isDropdownVisible: false,
      selectedItems: [],
      type: FilterType.leagues,
    },
    {
      placeholder: "Search country",
      query: "",
      items: [],
      isDropdownVisible: false,
      selectedItems: [],
      type: FilterType.countries,
    },
  ];

  constructor(private filterService: FilterService) {
    document.body.style.overflow = "hidden"; // Disable scrolling
  }

  ngOnInit(): void {
    for (let i = 0; i < 3; i++) {
      const top =
        i == 0
          ? this.filterService.topSports$
          : i == 1
          ? this.filterService.topLeagues$
          : this.filterService.topCountries$;
      this.subscriptions.push(
        top.subscribe((v) => {
          this.types[i].items = v;
        })
      );

      const filtered =
        i == 0
          ? this.filterService.filteredSports$
          : i == 1
          ? this.filterService.filteredLeagues$
          : this.filterService.filteredCountries$;
      this.subscriptions.push(
        filtered.subscribe((v) => {
          if (this.types[i].query && this.types[i].query.length > 0) {
            this.types[i].items = v;
          }
        })
      );

      this.subscriptions.push(
        this.filterService.selectedItems$.subscribe((v) => {
          this.types[0].selectedItems = v.sports;
          this.types[1].selectedItems = v.leagues;
          this.types[2].selectedItems = v.countries;
        })
      );
    }
  }

  onQueryChange(type: FilterType, query: string) {
    this.filterService.onSearchChanged(query, type);
    if (!query || query.length === 0) {
      const i = this.types.findIndex((x: any) => x.type === type);
      const top =
        i === 0
          ? this.filterService.topSports$
          : i === 1
          ? this.filterService.topLeagues$
          : this.filterService.topCountries$;

      this.types[i].items = top.getValue();
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      try {
        s.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    }
    this.subscriptions = [];
  }

  showDropdown(index: number): void {
    for (let i = 0; i < 3; i++) {
      if (i !== index) {
        this.types[i].isDropdownVisible = false;
      } else {
        this.types[index].isDropdownVisible = true;
      }
    }
  }

  hideDropdown(index: number): void {
    setTimeout(() => {
      this.types[index].isDropdownVisible = false;
    }, 200); // Timeout to allow click on dropdown items before hiding
  }

  closeSearch(index: number): void {
    this.types[index].query = "";
    this.types[index].isDropdownVisible = false;
  }

  isItemSelected(index: number, item: any) {
    return this.types[index].selectedItems.find((x: any) => x.id === item.id);
  }

  toggleSelection(index: number, item: any): void {
    const filterType = this.types[index];
    const itemIndex = filterType.selectedItems.findIndex(
      (x: any) => x.id === item.id
    );
    if (itemIndex === -1) {
      filterType.selectedItems.push(item); // Add item if not already selected
    } else {
      filterType.selectedItems.splice(itemIndex, 1); // Remove item if already selected
    }

    this.filterService.filterItemSelected(
      filterType.selectedItems,
      filterType.type
    );
  }

  removeSelection(index: number, item: any): void {
    const filter = this.types[index];
    filter.selectedItems = filter.selectedItems.filter(
      (i: any) => i.id !== item.id
    );

    this.filterService.filterItemSelected(filter.selectedItems, filter.type);
  }

  closeDialog(clear?: boolean) {
    if (clear) {
      for (let i = 0; i < 3; i++) {
        this.types[i].query = "";
        this.types[i].isDropdownVisible = false;
        this.types[i].selectedItems = [];
        this.filterService.filterItemSelected([], this.types[i].type);
        this.onQueryChange(this.types[i].type, "");
      }
    }

    document.body.style.overflow = ""; // Re-enable scrolling
    this.close.emit();
  }

  applyFilters() {
    this.closeDialog(false);
  }
}
