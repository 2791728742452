import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from "@angular/router";
import { CommonModule } from "@angular/common";
import { LocalizationModule } from "../localization";
import { Logout, ToggleSidePanel } from "../../store/user/user.actions";
import { Select, Store } from "@ngxs/store";
import { filter, findIndex, first, takeUntil } from "rxjs/operators";
import { UserState } from "../../store/user/user.store";
import { SafeTypes } from "../../shared/constants/safe-types.enum";
import { IconUventex } from "../../../assets/icons/uventex";
import { IconBars } from "../../../assets/icons/bars";
import { environment } from "src/environments/environment";
import { User } from "../../shared/models/user.model";
import { AuthTokenInfo } from "../../shared/models/auth-token.model";
import { NotificationTypes } from "../../shared/constants/notification-types.enum";
import { AuthContract } from "../../shared/contracts/auth.contract";
import { ToastService } from "../../shared/services/toast.service";
import { TranslocoService } from "@ngneat/transloco";
import { MatDialog } from "@angular/material/dialog";
import { LoginAsDialogComponent } from "../dialogs/login-as/login-as-dialog.component";
import { LoginByToken } from "../../store/user/user.actions";
import { DashboardService } from "src/app/shared/constants/dashboard.service";
import {DashboardActionModel} from "../../shared/models/dashboard-action.model";

@Component({
  standalone: true,
  selector: "app-left-panel",
  templateUrl: "./left-panel.component.html",
  styleUrls: ["./left-panel.component.scss"],
  imports: [CommonModule, LocalizationModule, RouterModule],
  providers: [ToastService],
})
export class LeftPanelComponent implements OnInit, OnDestroy {
  @HostBinding("class.open")
  private open: boolean = false;
  //@Input() public open!: any;

  @Output() public close = new EventEmitter<void>();
  @Output() public collapse = new EventEmitter<boolean>();

  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.isLoginAs)
  public isLoginAs$!: Observable<boolean>;

  user: any;

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  public isAdmin: boolean | null = null;

  @Select(UserState.toggleSidePanel)
  public toggleSidePanel$!: Observable<boolean>;

  private unsubscriber$ = new Subject<void>();

  @Select(UserState.selectedEvent)
  selectedEvent$!: Observable<any>;

  selectedEventId: any = null;

  public SafeTypes = SafeTypes;
  public IconUventex = IconUventex;
  public IconBars = IconBars;
  public baseUrl = environment.BASEURL.slice(
    environment.BASEURL.indexOf("/") + 2,
    -1
  );

  public menu: any;

  isCollapsed = false;

  isLeagueAvailable = false;

  accountHolderView = false;

  public isPublicTimeTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isMobile: boolean = false;

// For tracking expanded submenus (keyed by item name/id)
  expandedItems: { [key: string]: boolean } = {};

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private store: Store,
    private authContract: AuthContract,
    private toast: ToastService,
    private transloco: TranslocoService,
    private dashboardService: DashboardService,
    public dialog: MatDialog
  ) {
    this.isCollapsed = false;
  }

  public get isAccountHolderView() {
    return this.accountHolderView;
  }

  public async endSession(): Promise<void> {
    const authToken = await this.authContract.endSession({}).toPromise();
    if (authToken && authToken.accessToken) {
      await this.store
        .dispatch(new LoginByToken(authToken.accessToken))
        .toPromise();
    }
    this.ngOnInit();
  }

  public ngOnInit(): void {
    this.isLeagueAvailable = false;

    this.accountHolderView = false;

    this.authUser$?.subscribe((user) => {
      this.user = user;
      this.checkForLeaguePrivileges();

      if (!this.isAdmin) {
        if (
          user &&
          user.eventAccount &&
          user.eventAccount.accessLevel &&
          user.eventAccount.accessLevel === "USER"
        ) {
          this.accountHolderView = true;
        }
      }

      this.initMenu();
    });

    this.dashboardService.eventCount$.subscribe((data) => {
      this.initMenu();
    });

    this.isAdmin$.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
      this.initMenu();
    });

    this.toggleSidePanel$.subscribe((data) => {
      this.open = data;
      if (this.open) {
        document.body.style.overflow = "hidden"; // Disable scrolling
      } else {
        document.body.style.overflow = ""; // Re enable scrolling
      }
    });

    this.selectedEvent$.subscribe((event: any) => {
      this.selectedEventId = event ? event.id : null;
      this.initMenu();
    });

    this.checkScreenSize();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
    });
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth <= 768;
  }

  toggleSubMenu(item: any): void {
    // Toggle 'isExpanded' for the clicked menu item
    item.isExpanded = !item.isExpanded;

    // Collapse all child submenus if the parent is collapsed
    if (!item.isExpanded && item.subMenu?.length) {
      this.collapseAllSubMenus(item.subMenu);
    }
  }

  collapseAllSubMenus(subMenu: any[]): void {
    subMenu.forEach(child => {
      child.isExpanded = false; // Collapse the submenu
      if (child.subMenu?.length) {
        this.collapseAllSubMenus(child.subMenu); // Recursively collapse nested submenus
      }
    });
  }


  addEvent() {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;

    let returnUrl = encodeURIComponent(window.location.href).replaceAll(
      "%2F",
      "___"
    );
    const url = `${environment.EVENT_PORTAL_URL}#proposalRequest;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
    window.location.href = url;
  }

  manageYourAccount() {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;

    let returnUrl = encodeURIComponent(window.location.href).replaceAll(
      "%2F",
      "___"
    );
    const url = `${environment.EVENT_PORTAL_URL}#account;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
    window.location.href = url;
  }

  public ngOnDestroy() {}

  public openPanel() {
    this.store.dispatch(new ToggleSidePanel(true));
  }

  public closePanel() {
    this.store.dispatch(new ToggleSidePanel(false));
    this.close.emit();
  }

  private listenNavigation(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((event) => event instanceof NavigationEnd),
        filter(() => this.open)
      )
      .subscribe((k) => {
        this.closePanel();
      });
  }

  getFullName() {
    if (this.user) {
      return this.user.fullName
        ? this.user.fullName
        : this.user.firstName + " " + this.user.lastName;
    }

    return null;
  }

  getEmail() {
    return this.user ? this.user.email : null;
  }

  handleLoginAs() {
    const dialogRef = this.dialog
      .open(LoginAsDialogComponent, {
        width: "460px",
        panelClass: "custom-dialog-container",
        data: {
          transloco: this.transloco,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((v) => {
        this.ngOnInit();
      });
  }

  private initMenu() {
    const adminMenu = {
      name: "ROLE_ADMIN",
      link: "/dashboard/admin",
      links: ["/dashboard/admin"],
      show: true,
      icon: "/assets/icons/ic-admin-dashboard.svg",
      isExpanded: false,
      subMenu: [
        {
          name: "ACCOUNTS_AND_ROLES",
          link: "/dashboard/admin/accounts",
          show: true,
          subMenu: this.dashboardService.getAdminRowAccountActions().map(action => ({
            name: action.label,
            link: action.routerLink,
            url: action.url,
            show: true,
          })),
        },
        {
          name: "FINANCES",
          link: "/dashboard/admin/finances",
          show: true,
          subMenu: this.dashboardService.getAdminRowFinancesActions().map(action => ({
            name: action.label,
            link: action.routerLink,
            url: action.url,
            show: true,
          }))
        },
      ],
    };

    const eventMenu: any = {
      name: "EVENT",
      link: "/dashboard/event" + this.getSelectedEventIdStrVal(),
      links: ["/dashboard/event" + this.getSelectedEventIdStrVal()],
      show: true,
      active: false,
      isExpanded: false,
      icon: "/assets/icons/event-stadium.svg",
      subMenu: this.dashboardService.getEventViewActions().map(action => ({
        name: action.label,
        link: action.routerLink,
        subMenu: action.children ? this.getChildren(action) : [],
        url: action.url,
        show: true,
      })),
    };

    const accHolderMenu = {
      name: "ACCOUNT_HOLDER",
      link: `/dashboard/event/account-holder`,
      show: true,
      subMenu: this.dashboardService.getAccountHoldersAction().map(action => ({
        name: action.label,
        link: action.routerLink,
        subMenu: action.children ? this.getChildren(action) : [],
        url: action.url,
        show: true,
      })),
    };

    const eventTeam: any = {
      name: "EVENT_TEAM",
      link: "/dashboard/event" + this.getSelectedEventIdStrVal(),
      show: true,
      isExpanded: false,
      subMenu: this.dashboardService.getLeagueActions().map(action => ({
        name: action.label,
        link: action.routerLink,
        subMenu: action.children ? this.getChildren(action) : [],
        url: action.url,
        show: true,
      })),
    };

    const dataCount = this.dashboardService.eventCount$.getValue();

    const league = {
      name: "LEAGUE",
      link: "/dashboard/league",
      show: true,
      icon: "/assets/icons/dash-league-icon.svg",
      subMenu: this.dashboardService.getLeagueActions().map(action => ({
        name: action.label,
        link: action.routerLink,
        subMenu: action.children ? this.getChildren(action) : [],
        url: action.url,
        show: true,
      })),
    };

    this.menu = [];
    if (this.isAdminUser) {
      this.menu.push(adminMenu);
      this.menu.push(eventMenu);
    } else {
      if (this.isAccountHolderView) {
        eventMenu.subMenu.push(accHolderMenu);
      } else {
        if (
          this.user &&
          this.user.eventAccount &&
          this.user.eventAccount.allowedEventIds &&
          this.user.eventAccount.allowedEventIds.length > 0
        ) {
          eventMenu.subMenu.push(accHolderMenu);
          eventMenu.subMenu.push(eventTeam);
          this.menu.push(eventMenu);
        }
      }

      if (this.isLeagueAvailable) {
        this.menu.push(league);
      }
    }
  }

  private getChildren(action: DashboardActionModel): any[] {
    if (!action.children || action.children.length === 0) {
      return [];
    }
      return action.children.map(child => ({
          name: child.label,
          link: child.routerLink,
          subMenu: child.children ? this.getChildren(child) : [],
          url: child.url,
          click: ()=>this.handleMenuClick(action),
          toggle: this.getToggle(child.label),
          show: true,
      }));
  }

  handleMenuClick(action: any) {
    if (action.name === "PUBLIC_TIMETABLE") {
      this.dashboardService.isPublicTimeTableMenuClick$.next(true);
    } else if (action.name === "PUBLIC_SCHEDULE") {
      this.dashboardService.isPublicDrawsEnabled$MenuClick$.next(true);
    } else if (action.name === "DELETE_ALL_SCORES") {
      this.dashboardService.deleteAllScoresMenuClick$.next(true);
    }
    return null;

  }


  private getToggle(label: any) {
    if (label === "PUBLIC_TIMETABLE") {
      return this.dashboardService.isPublicTimeTableEnabled$;
    } else if (label === "PUBLIC_SCHEDULE") {
      return this.dashboardService.isPublicDrawsEnabled$;
    }
    return null;
  }

  onMouseEnter(menuItem: any) {
    menuItem.isExpanded = true;
  }

  onMouseLeave(menuItem: any) {
    menuItem.isExpanded = false;
  }


  public get isAdminUser() {
    return this.isAdmin === true;
  }

  checkForLeaguePrivileges() {
    const user = this.user;

    let result = false;
    if (user && user.eventAccount && user.eventAccount.roleList) {
      const roleList = user.eventAccount.roleList;
      if (roleList.length > 0) {
        const length = roleList.length;
        for (let i = 0; i < length; i++) {
          const role = roleList[i];
          if (
            role.hasLeaguePrivileges === true
            // || role.isLeague === true
          ) {
            result = true;
            break;
          }
        }
      }
    }

    this.isLeagueAvailable = result;
  }

  getSelectedEventIdStrVal() {
    return this.selectedEventId > 0 ? `/${this.selectedEventId}` : "";
  }

  handleLogOut() {
    this.authContract.logout().subscribe({
      next: (value: AuthTokenInfo) => {
        this.store.dispatch(new Logout());
        this.router.navigateByUrl("");
      },
      error: (error: any) => {
        const body = this.transloco.translate("ALERT_LOGOUT_FAILED");
        this.toast.show({ body, type: NotificationTypes.error });
      },
    });
  }

  expandCollapsePanel() {
    this.isCollapsed = !this.isCollapsed;
    this.collapse.next(this.isCollapsed);
  }
}
