import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Currency } from "src/app/shared/models/currency.model";
import { Country } from "src/app/shared/models/country.model";
import { State } from "src/app/shared/models/state.model";
import { environment } from "../../../environments/environment";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DictionariesContract {
  constructor(private http: HttpClient) {}

  public getCurrencies() {
    //return this.http.get<Currency[]>(`${environment.studioApi}api/v1/dictionary/currency`, { headers: { appversion: '200' } });
    return this.http.get<Currency[]>(
      `${environment.APIURL}api/public/dictionary/studio/currency`
    );
  }

  public getCountries(name: string = "") {
    const filter = {
      page: 0,
      size: 1000,
      filter: {
        name,
      },
    };

    const phonePrefixMap = phonePrefixes.reduce((acc, curr: any) => {
      const key = Object.keys(curr)[0];
      acc[key] = curr[key];
      return acc;
    }, {} as Record<string, string>);

    return this.http
      .post<Country[]>(
        `${environment.APIURL}api/public/dictionary/event/countries/filter`,
        filter
      )
      .pipe(
        // Merge the phone prefix into each country object
        map((countries: Country[]) =>
          countries.map((country) => ({
            ...country,
            phonePrefix: phonePrefixMap[country.code] || "",
            imageUrl:
              "https://cdn.uventex.com/events/images/flags/" +
              country.code +
              ".png",
          }))
        )
      );
  }

  public getStatesOrProvinces(countryId: number, name: string = "") {
    const filter = {
      page: 0,
      size: 1000,
      filter: {
        name,
        countryId,
      },
    };

    return this.http.post<State[]>(
      `${environment.APIURL}api/public/dictionary/event/states/filter`,
      filter
    );
  }
}

const phonePrefixes = [
  { AF: "+93" },
  { AL: "+355" },
  { DZ: "+213" },
  { AS: "+1-684" },
  { AD: "+376" },
  { AO: "+244" },
  { AI: "+1-264" },
  { AQ: "" },
  { AG: "+1-268" },
  { AR: "+54" },
  { AM: "+374" },
  { AW: "+297" },
  { AU: "+61" },
  { AT: "+43" },
  { AZ: "+994" },
  { BS: "+1-242" },
  { BH: "+973" },
  { BD: "+880" },
  { BB: "+1-246" },
  { BY: "+375" },
  { BE: "+32" },
  { BZ: "+501" },
  { BJ: "+229" },
  { BM: "+1-441" },
  { BT: "+975" },
  { BO: "+591" },
  { BA: "+387" },
  { BW: "+267" },
  { BV: "" },
  { BR: "+55" },
  { IO: "+246" },
  { BN: "+673" },
  { BG: "+359" },
  { BF: "+226" },
  { BI: "+257" },
  { KH: "+855" },
  { CM: "+237" },
  { CA: "+1" },
  { CV: "+238" },
  { KY: "+1-345" },
  { CF: "+236" },
  { TD: "+235" },
  { CL: "+56" },
  { CN: "+86" },
  { CX: "+61" },
  { CC: "+61" },
  { CO: "+57" },
  { KM: "+269" },
  { CG: "+242" },
  { CD: "+243" },
  { CK: "+682" },
  { CR: "+506" },
  { CI: "+225" },
  { HR: "+385" },
  { CU: "+53" },
  { CY: "+357" },
  { CZ: "+420" },
  { DK: "+45" },
  { DJ: "+253" },
  { DM: "+1-767" },
  { DO: "+1-809" },
  { TP: "+670" },
  { EC: "+593" },
  { EG: "+20" },
  { SV: "+503" },
  { GQ: "+240" },
  { ER: "+291" },
  { EE: "+372" },
  { ET: "+251" },
  { FK: "+500" },
  { FO: "+298" },
  { FJ: "+679" },
  { FI: "+358" },
  { FR: "+33" },
  { GF: "+594" },
  { PF: "+689" },
  { TF: "" },
  { GA: "+241" },
  { GM: "+220" },
  { GE: "+995" },
  { DE: "+49" },
  { GH: "+233" },
  { GI: "+350" },
  { GR: "+30" },
  { GL: "+299" },
  { GD: "+1-473" },
  { GP: "+590" },
  { GU: "+1-671" },
  { GT: "+502" },
  { GN: "+224" },
  { GW: "+245" },
  { GY: "+592" },
  { HT: "+509" },
  { HM: "" },
  { VA: "+379" },
  { HN: "+504" },
  { HK: "+852" },
  { HU: "+36" },
  { IS: "+354" },
  { IN: "+91" },
  { ID: "+62" },
  { IR: "+98" },
  { IQ: "+964" },
  { IE: "+353" },
  { IL: "+972" },
  { IT: "+39" },
  { JM: "+1-876" },
  { JP: "+81" },
  { JO: "+962" },
  { KZ: "+7" },
  { KE: "+254" },
  { KI: "+686" },
  { KP: "+850" },
  { KR: "+82" },
  { XK: "+383" },
  { KW: "+965" },
  { KG: "+996" },
  { LA: "+856" },
  { LV: "+371" },
  { LB: "+961" },
  { LS: "+266" },
  { LR: "+231" },
  { LY: "+218" },
  { LI: "+423" },
  { LT: "+370" },
  { LU: "+352" },
  { MO: "+853" },
  { MK: "+389" },
  { MG: "+261" },
  { MW: "+265" },
  { MY: "+60" },
  { MV: "+960" },
  { ML: "+223" },
  { MT: "+356" },
  { MH: "+692" },
  { MQ: "+596" },
  { MR: "+222" },
  { MU: "+230" },
  { YT: "+262" },
  { MX: "+52" },
  { FM: "+691" },
  { MD: "+373" },
  { MC: "+377" },
  { MN: "+976" },
  { ME: "+382" },
  { MS: "+1-664" },
  { MA: "+212" },
  { MZ: "+258" },
  { MM: "+95" },
  { NA: "+264" },
  { NR: "+674" },
  { NP: "+977" },
  { NL: "+31" },
  { AN: "" },
  { NC: "+687" },
  { NZ: "+64" },
  { NI: "+505" },
  { NE: "+227" },
  { NG: "+234" },
  { NU: "+683" },
  { NF: "+672" },
  { MP: "+1-670" },
  { NO: "+47" },
  { OM: "+968" },
  { PK: "+92" },
  { PW: "+680" },
  { PS: "+970" },
  { PA: "+507" },
  { PG: "+675" },
  { PY: "+595" },
  { PE: "+51" },
  { PH: "+63" },
  { PN: "+64" },
  { PL: "+48" },
  { PT: "+351" },
  { PR: "+1-787" },
  { QA: "+974" },
  { RE: "+262" },
  { RO: "+40" },
  { RU: "+7" },
  { RW: "+250" },
  { SH: "+290" },
  { KN: "+1-869" },
  { LC: "+1-758" },
  { PM: "+508" },
  { VC: "+1-784" },
  { WS: "+685" },
  { SM: "+378" },
  { ST: "+239" },
  { SA: "+966" },
  { GB_ENG: "+44" },
  { SN: "+221" },
  { RS: "+381" },
  { SC: "+248" },
  { SL: "+232" },
  { SG: "+65" },
  { SK: "+421" },
  { SI: "+386" },
  { SB: "+677" },
  { SO: "+252" },
  { ZA: "+27" },
  { GS: "" },
  { ES: "+34" },
  { LK: "+94" },
  { SD: "+249" },
  { SR: "+597" },
  { SJ: "" },
  { SZ: "+268" },
  { SE: "+46" },
  { CH: "+41" },
  { SY: "+963" },
  { TW: "+886" },
  { TJ: "+992" },
  { TZ: "+255" },
  { TH: "+66" },
  { TG: "+228" },
  { TK: "+690" },
  { TO: "+676" },
  { TT: "+1-868" },
  { TN: "+216" },
  { TR: "+90" },
  { TM: "+993" },
  { TC: "+1-649" },
  { TV: "+688" },
  { UG: "+256" },
  { UA: "+380" },
  { AE: "+971" },
  { GB: "+44" },
  { US: "+1" },
  { UM: "+1" },
  { UY: "+598" },
  { UZ: "+998" },
  { VU: "+678" },
  { VE: "+58" },
  { VN: "+84" },
  { VG: "+1-284" },
  { VI: "+1-340" },
  { GB_NIR: "+44" },
  { GB_SCT: "+44" },
  { GB_WLS: "+44" },
  { WF: "+681" },
  { EH: "" },
  { YE: "+967" },
  { ZM: "+260" },
  { ZW: "+263" },
];
