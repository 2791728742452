import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

// tslint:disable-next-line: max-line-length
export const firstLastNamesPattern = /^(?!\s*$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9]+(?:\s+[a-zA-Z0-9]+)*$/i;

export const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const loginAsEmailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s\"]+\.)+[^<>()[\]\.,;:\s\"]{2,})$/i;

export const passwordPattern = /^\S{8,}$/;

export const signinPasswordPattern = /^\S{2,}$/;

export const DeepFormGroupBuilder = (object: any): FormGroup => {
  if (String(object) !== '[object Object]') {
    throw new Error(`Can't build now object value`);
  }

  const buildObject = (value: any): AbstractControl => {
    return Object.entries(value).reduce((form, [key, val]) => {
      if (Array.isArray(val)) {
        form.addControl(key, new FormArray(val.map((item) => buildObject(item))));
        return form;
      }

      if (String(val) === '[object Object]') {
        form.addControl(key, buildObject(val));
        return form;
      }

      form.addControl(key, new FormControl(val));
      return form;
    }, new FormGroup({}));
  };

  return buildObject(object) as FormGroup;
};

export const NumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const number = Number.parseFloat(control.value);

  if (Number.isNaN(number)) {
    return { isNotNumber: true };
  }

  if (!/^[\d\.\,]+$/.test(String(control.value))) {
    return { isNotNumber: true };
  }

  return null;
};

export const Patterns = {
  email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  password: /^\S{8,}$/,
};
