
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, ViewChild } from '@angular/core';
import { Observable } from "rxjs";
import { AsyncPipe, CommonModule } from "@angular/common";
import { SafePipe } from "../../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../../shared/constants/safe-types.enum";
import { CarouselComponent, CarouselModule, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { CustomDialogComponent } from 'src/app/shared/models/custom-dialog-component.model';

@Component({
  selector: 'ui-img-slide',
  templateUrl: './img-slide.component.html',
  styleUrls: ['./img-slide.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SafePipe,
    AsyncPipe,
    CarouselModule,
  ]
})
export class ImgSlideComponent implements AfterViewInit, CustomDialogComponent<number, any> {
  ngAfterViewInit(): void {
    this.owlCar!.to(this.params.link)
  }

  @ViewChild('owlCar') owlCar: CarouselComponent | undefined;
  public params: any;
  public dialogResult = new EventEmitter<any>();
  public outsideClick$!: Observable<void>;
  public SafeTypes = SafeTypes;
  public selectedImage!: string;
  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    margin: 8,
    navText: ["<div class='slide-prev'></div>", "<div class='slide-next'></div>"],
    nav: true,
    center: true,
    items: 1,
    autoWidth: true
  };

  changeImage(image: string) {
    this.selectedImage = image;
  }

  changedByNav(data: SlidesOutputData) {
    if (data.slides?.length) {
      this.selectedImage = data.slides[0].id;
    }
  }

  getAspectRatioClass(image: {
    width: number;
    height: number;
    aspectRatio: number;
  }): string {
    if (image.aspectRatio < 0.45) {
      return "x-narrow-image";
    } else if (image.aspectRatio < 0.8) {
      return "narrow-image";
    } else if (image.aspectRatio > 1) {
      return "wide-image"; // Very wide images
    } else {
      return "standard-image"; // Normal aspect ratios
    }
  }
}