import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapchaService } from "../../../shared/services/captcha.service";
import { TranslocoService } from '@ngneat/transloco';
import { LocalizationModule } from "../../localization";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { emailPattern, firstLastNamesPattern, passwordPattern } from "../../../../app/utils/control-utils";
import { AuthContract } from "../../../shared/contracts/auth.contract";
import { CreateUserRequest } from "../../../shared/models/create-user-request.model";
import { AuthTokenInfo } from "../../../shared/models/auth-token.model";
import { LoginByToken } from "../../../store/user/user.actions";
import { Store } from "@ngxs/store";
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'events-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  imports: [
    LocalizationModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  providers: [TranslocoService],
  encapsulation: ViewEncapsulation.None,
})
export class SignUpComponent implements OnInit {
  @Input() signInAndClose: any;

  @Output() signUpSuccessed = new EventEmitter();

  @Output() displaySignIn = new EventEmitter();

  public isLoading = false;

  hidePassword = true;
  registrationComplete = false;

  userData = {
    username: '',
    password: '',
    firstName: '',
    lastName: '',
  };

  public firstNameControl = new FormControl('', [Validators.required, Validators.pattern(firstLastNamesPattern), Validators.max(50)]);
  public lastNameControl = new FormControl('', [Validators.required, Validators.pattern(firstLastNamesPattern), Validators.max(50)]);
  public usernameControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(emailPattern)]);
  public passwordControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(passwordPattern)]);

  public policyControl = new FormControl(false, Validators.requiredTrue);
  public termsOfServiceControl = new FormControl(false, Validators.requiredTrue);

  public signupForm = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    username: this.usernameControl,
    password: this.passwordControl,
    policy: this.policyControl,
    termsOfService: this.termsOfServiceControl,
  });

  constructor(
    private authContract: AuthContract,
    private toastr: ToastrService,
    private transloco: TranslocoService,
    private capchaService: CapchaService,
    private store: Store,
  ) {}

  ngOnInit() {
  }

  public registerUser() {
    const reqObj: CreateUserRequest = {
      firstName: this.firstNameControl.value!,
      lastName: this.lastNameControl.value!,
      email: this.usernameControl.value!,
      plainPassword: this.passwordControl.value!,
    };

    this.isLoading = true;

    this.authContract.registerUser(reqObj).subscribe(
      (userData) => {
        if (this.signInAndClose) {
          const email = this.usernameControl.value;
          const password = this.passwordControl.value;
          this.login(email, password);
        } else {
          this.registrationComplete = true;
          this.clearFilledData();
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;

        const message = error.error.message;
        const title = this.transloco.translate('REGISTRATION_NOT_FINISHED');
        this.toastr.warning(message, title, { closeButton: true });
      },
    );
  }

  private clearFilledData() {
    this.userData.firstName = '';
    this.userData.lastName = '';
    this.userData.password = '';
    this.userData.username = '';
    this.policyControl.setValue(false, { emitEvent: false, onlySelf: true });
    this.policyControl.markAsUntouched();
    this.termsOfServiceControl.setValue(false, { emitEvent: false, onlySelf: true });
    this.termsOfServiceControl.markAsUntouched();

    this.firstNameControl.setValue('', { emitEvent: false, onlySelf: true });
    this.lastNameControl.setValue('', { emitEvent: false, onlySelf: true });
    this.usernameControl.setValue('', { emitEvent: false, onlySelf: true });
    this.passwordControl.setValue('', { emitEvent: false, onlySelf: true });
  }

  public async onFormSubmit() {
    if (this.signupForm.invalid || !this.termsOfServiceControl.value || !this.policyControl.value) {
      this.termsOfServiceControl.markAsTouched();
      this.policyControl.markAsTouched();
      return;
    }

    //const capchaToken = await this.capchaService.getSubmitToken();
    //this.registerUser(capchaToken);
    this.registerUser();
  }

  public get privacyPolicyLink(): string | undefined {
    return  environment.policiesLinks.find(x=> x.key === "privacy")?.external;
    //const link = environment.PRIVACY_POLICY_LINK.get(this.transloco.getActiveLang() as SupportedLocales);
    //return (link ? link : '');
  }

  public get termsPolicyLink(): string | undefined {
    return environment.policiesLinks.find(x=> x.key === "terms")?.external;
    //const link = environment.PRIVACY_POLICY_LINK.get(this.transloco.getActiveLang() as SupportedLocales);
    //return (link ? link : '');
  }

  public openPrivacyPolicyLink(): void {
    const windowGlobal = window.hasOwnProperty('cordova') ? (window as any).cordova.InAppBrowser : window;
    windowGlobal.open(this.privacyPolicyLink, '_system');
  }

  public openTermsLink(): void {
    const windowGlobal = window.hasOwnProperty('cordova') ? (window as any).cordova.InAppBrowser : window;
    windowGlobal.open(this.termsPolicyLink, '_system');
  }

  displaySignInForm() {
    this.displaySignIn.emit();
  }

  login(email: string, password: string) {
    this.authContract.login(email, password, 'password').subscribe({
          next: (value: AuthTokenInfo) => {
            if (value != null && value.accessToken != null) {
              this.store.dispatch(new LoginByToken(value.accessToken)).subscribe((result) => {
              });
            }

            this.signUpSuccessed.emit();
          },
          error: (error: any) => {
            if (error && error.error && error.error.code) {
              const code = error.error.code;
              if (code === 401) {
              }
            } else {
              const body = this.transloco.translate('ALERT_LOGIN_FAILED');
              this.toastr.error(body);
            }
          }
        }
    );
  }

}
