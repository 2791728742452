import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {User} from "../models/user.model";
import {CreateUserRequest} from "../models/create-user-request.model";
import {from, Observable, switchMap} from "rxjs";
import {AuthToken, AuthTokenInfo} from "../models/auth-token.model";
import {CapchaService} from "../services/captcha.service";

@Injectable({
  providedIn: 'root'
})
export class AuthContract {
  constructor(private http: HttpClient, private capchaService: CapchaService) {}

  public getAuthByToken(token: string): Observable<User> {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + token, 'Accept': 'application/json', 'Content-Type': 'application/json'});
    let result = this.http.get<User>(`${environment.APIURL}api/user/self`, {headers: headers});

    return result;
  }

  public login(username: string, password: string, grantType: 'password' | 'google' | 'facebook' | 'apple', token?:string) {
    const data:any = { username, password, grantType: grantType }
    if (token) {
      data.authToken = token;
    }
    return this.http.post<AuthTokenInfo>(`${environment.APIURL}api/auth/token`, data);
    /*return from(this.capchaService.getSubmitToken()).pipe(
      switchMap((recaptchaToken) =>
        this.http.post<AuthTokenInfo>(`${environment.APIURL}api/auth/token`, { username: login, password, grantType: 'password', recaptchaToken }),
      ),
    );*/
  }

  public loginAsSearch(username: string) {
    return this.http.post<User[]>(`${environment.APIURL}api/auth/login-as/search`, { username });
  }

  public loginAs(username: string) {
    return this.http.post<AuthTokenInfo>(`${environment.APIURL}api/auth/login-as`, { username, grantType: 'loginas' });
  }

  public endSession(loginAsToken: {}) {
    return this.http.post<AuthTokenInfo>(`${environment.APIURL}api/auth/login-as/return`, {});
  }

  public logout() {
    return this.http.post<AuthTokenInfo>(`${environment.APIURL}api/auth/logout`, {});
  }

  public registerUser(userData: CreateUserRequest) {
    return this.http.post<User>(`${environment.APIURL}api/user/public/signup`, { ...userData });
    /*return from(this.capchaService.getSubmitToken()).pipe(
      switchMap((recaptchaToken) =>
        this.http.post<User>(`${environment.APIURL}api/user/register`, { ...userData, recaptchaToken }),
      ),
    );*/
  }

  public joinToUventex(userData: any) {
    return this.http.post<String>(`${environment.APIURL}api/user/public/join-to-uventex`, { ...userData });
  }

  public generateToken(username: string, password: string, recaptchaToken: string) {
    return this.http.post<AuthToken>(`${environment.APIURL}auth/generate-token`, { authUserRequest: { username, password }, recaptchaToken });
  }

  public resetPassword(email: string) {
    return this.http.post<{ message: string; reset: boolean }>(`${environment.APIURL}api/user/public/change-password/request`, { email });
  }
  
  public changePassword(password: any, resetToken: string | null) {
    return this.http.post<{ message: string; reset: boolean }>(`${environment.APIURL}api/user/public/change-password/apply`, { token: resetToken, password });
  }

}
