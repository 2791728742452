import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  constructor() {
    this.setTheme({
      "U-colors-Blue": "#01a1da",
      // "primary-color": "#ff5733",
      // "border-radius": "12px",
    });
  }

  setTheme(theme: { [key: string]: string }) {
    Object.keys(theme).forEach((key) => {
      document.documentElement.style.setProperty(`--${key}`, theme[key]);
    });
  }
}
