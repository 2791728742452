<div class="overlay" (click)="closeDialog()">
  <div class="dialog" (click)="$event.stopPropagation()">
    <div class="header">
      <button class="cancel" (click)="closeDialog(true)">
        Clear <strong><small>x</small></strong>
      </button>
      <h2 class="title">Filter</h2>
      <button class="done" (click)="applyFilters()">Done</button>
    </div>
    <div class="content">
      <!-- <div class="field">
        <span class="icon">📍</span>
        <span class="label">Location</span>
        <span class="value">Caroline, Windowsdow 45...</span>
      </div>
      <div class="field">
        <span class="icon">📅</span>
        <span class="label">Dates</span>
        <span class="value">Feb 14, 2025 - Feb 14, 2025</span>
      </div> -->
      <div
        *ngFor="let filterType of types; let i = index"
        class="input-wrapper"
      >
        <input
          class="f-search"
          type="text"
          [placeholder]="filterType.placeholder"
          [(ngModel)]="filterType.query"
          (ngModelChange)="onQueryChange(filterType.type, filterType.query)"
          (focus)="showDropdown(i)"
        />
        <span
          class="close-label"
          *ngIf="filterType.isDropdownVisible"
          (click)="closeSearch(i)"
          >Close</span
        >
        <ul class="dropdown" *ngIf="filterType.isDropdownVisible">
          <li
            *ngFor="let item of filterType.items"
            [class.selected]="isItemSelected(i, item)"
            (click)="toggleSelection(i, item)"
          >
            <img class="logo" src="{{ item.url }}" />

            <span class="name">{{ item.name }}</span>
            <span class="selected-indicator" *ngIf="isItemSelected(i, item)">
              ✔
            </span>
          </li>
        </ul>
        <div class="selected-tags">
          <span
            *ngFor="let selectedItem of filterType.selectedItems"
            class="tag"
          >
            <img class="small-logo" src="{{ selectedItem.url }}" />

            {{ selectedItem.name }}
            <span class="remove-tag" (click)="removeSelection(i, selectedItem)"
              >×</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
