export const IconSwitchOff = `<svg
            class="switch1"
            width="38"
            height="24"
            viewBox="0 0 38 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 2245">
              <rect
                id="Rectangle 3330"
                x="2"
                y="4"
                width="36"
                height="14"
                rx="7"
                fill="#FFCECE"
              />
              <g id="Ellipse 1830" filter="url(#filter0_d_2436_1502)">
                <circle cx="12" cy="11" r="10" fill="#E15F5F" />
              </g>
            </g>
            <defs>
              <filter
                id="filter0_d_2436_1502"
                x="0"
                y="0"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2436_1502"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2436_1502"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>`;
