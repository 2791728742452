export const IconSwitchOn = `<svg
class="switch1"
width="38"
height="24"
viewBox="0 0 38 24"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<g id="Group 2246">
  <rect
    id="Rectangle 3330"
    y="4"
    width="36"
    height="14"
    rx="7"
    fill="#EEF5D0"
  />
  <g id="Ellipse 1830" filter="url(#filter0_d_2436_1275)">
    <circle cx="26" cy="11" r="10" fill="#CDD620" />
  </g>
</g>
<defs>
  <filter
    id="filter0_d_2436_1275"
    x="14"
    y="0"
    width="24"
    height="24"
    filterUnits="userSpaceOnUse"
    color-interpolation-filters="sRGB"
  >
    <feFlood flood-opacity="0" result="BackgroundImageFix" />
    <feColorMatrix
      in="SourceAlpha"
      type="matrix"
      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      result="hardAlpha"
    />
    <feOffset dy="1" />
    <feGaussianBlur stdDeviation="1" />
    <feComposite in2="hardAlpha" operator="out" />
    <feColorMatrix
      type="matrix"
      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    />
    <feBlend
      mode="normal"
      in2="BackgroundImageFix"
      result="effect1_dropShadow_2436_1275"
    />
    <feBlend
      mode="normal"
      in="SourceGraphic"
      in2="effect1_dropShadow_2436_1275"
      result="shape"
    />
  </filter>
</defs>
</svg>`;
