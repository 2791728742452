<div class="c-pad custom-component-theme">
  <div class="header">
    <div class="left-header">
      <div class="title-section">
        <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.9844 0.65625C14.4141 0.34375 15 0.617188 15 1.125V19.875C15 20.4219 14.375 20.6953 13.9844 20.3828L12.5 18.625L10.3516 20.3828C10.2344 20.4609 10.1172 20.5391 9.96094 20.5391C9.84375 20.5391 9.72656 20.4609 9.60938 20.3828L7.5 18.625L5.35156 20.3828C5.23438 20.4609 5.11719 20.5391 4.96094 20.5391C4.84375 20.5391 4.72656 20.4609 4.60938 20.3828L2.5 18.625L0.976562 20.3828C0.585938 20.6953 0 20.4219 0 19.875V1.125C0 0.617188 0.585938 0.34375 0.976562 0.65625L2.5 2.375L4.60938 0.65625C4.72656 0.578125 4.84375 0.5 5 0.5C5.11719 0.5 5.23438 0.578125 5.35156 0.65625L7.5 2.375L9.60938 0.65625C9.72656 0.578125 9.84375 0.5 10 0.5C10.1172 0.5 10.2344 0.578125 10.3516 0.65625L12.5 2.375L13.9844 0.65625ZM12.5 14.5625V13.9375C12.5 13.7812 12.3438 13.625 12.1875 13.625H2.8125C2.61719 13.625 2.5 13.7812 2.5 13.9375V14.5625C2.5 14.7578 2.61719 14.875 2.8125 14.875H12.1875C12.3438 14.875 12.5 14.7578 12.5 14.5625ZM12.5 10.8125V10.1875C12.5 10.0312 12.3438 9.875 12.1875 9.875H2.8125C2.61719 9.875 2.5 10.0312 2.5 10.1875V10.8125C2.5 11.0078 2.61719 11.125 2.8125 11.125H12.1875C12.3438 11.125 12.5 11.0078 12.5 10.8125ZM12.5 7.0625V6.4375C12.5 6.28125 12.3438 6.125 12.1875 6.125H2.8125C2.61719 6.125 2.5 6.28125 2.5 6.4375V7.0625C2.5 7.25781 2.61719 7.375 2.8125 7.375H12.1875C12.3438 7.375 12.5 7.25781 12.5 7.0625Z"
            fill="#0E2A8D" />
        </svg>
        <span class="title">{{ "NAV_ROSTER_STEP_FOUR" | transloco }}</span>
      </div>
      <span class="subtitle">{{ "NAV_ROSTER_ORDERS_DESCR" | transloco }}</span>
    </div>
    <div class="header-middle-buttons">
      <button class="small-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M1.125 1.70312C0.503906 1.70312 0 2.24063 0 2.90313C0 3.28063 0.166406 3.63562 0.45 3.86312L5.55 7.94313C5.81719 8.15563 6.18281 8.15563 6.45 7.94313L11.55 3.86312C11.8336 3.63562 12 3.28063 12 2.90313C12 2.24063 11.4961 1.70312 10.875 1.70312H1.125ZM0 4.50313V9.70312C0 10.5856 0.672656 11.3031 1.5 11.3031H10.5C11.3273 11.3031 12 10.5856 12 9.70312V4.50313L6.9 8.58313C6.36562 9.01063 5.63437 9.01063 5.1 8.58313L0 4.50313Z"
            fill="white" />
        </svg> </button>
      <button class="small-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M6.75 0.75C6.75 0.335156 6.41484 0 6 0C5.58516 0 5.25 0.335156 5.25 0.75V6.43828L3.52969 4.71797C3.23672 4.425 2.76094 4.425 2.46797 4.71797C2.175 5.01094 2.175 5.48672 2.46797 5.77969L5.46797 8.77969C5.76094 9.07266 6.23672 9.07266 6.52969 8.77969L9.52969 5.77969C9.82266 5.48672 9.82266 5.01094 9.52969 4.71797C9.23672 4.425 8.76094 4.425 8.46797 4.71797L6.75 6.43828V0.75ZM1.5 8.25C0.672656 8.25 0 8.92266 0 9.75V10.5C0 11.3273 0.672656 12 1.5 12H10.5C11.3273 12 12 11.3273 12 10.5V9.75C12 8.92266 11.3273 8.25 10.5 8.25H8.12109L7.05938 9.31172C6.47344 9.89766 5.52422 9.89766 4.93828 9.31172L3.87891 8.25H1.5ZM10.125 10.6875C9.81328 10.6875 9.5625 10.4367 9.5625 10.125C9.5625 9.81328 9.81328 9.5625 10.125 9.5625C10.4367 9.5625 10.6875 9.81328 10.6875 10.125C10.6875 10.4367 10.4367 10.6875 10.125 10.6875Z"
            fill="white" />
        </svg> </button>
    </div>
    <img class="logo" src="assets/img/aau-logo.png" />
  </div>

  <div class="m-card" *ngFor="let order of orders">
    <div class="c-card-header" (click)="expand(order)">
      <div class="info">
        <div class="m-title-section">
          <div class="m-title">
            <span>{{ "ORDER" | transloco }}</span>
            <span>{{ order.orderNo }}</span>
            <span class="order-total">{{ order.currencyCode + " " + (order.totalPrice | number : "1.2-2") }}</span>
          </div>
        </div>
        <div class="order-subtitle">
          <div class="date-time">
            <span>{{ order.operationDate | date }}</span>
            <span>{{ getHourAndMinutesAMPM(order.updateDate) }} </span>
          </div>
          <span>{{ capitalizeFirstLetter(order.paymentMethod) }}</span>
        </div>
      </div>
      <div class="expand">
        <svg [class.rotate]="order.expanded" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
          fill="none">
          <path
            d="M7.54639 11.8144L2.18557 6.4811C1.93814 6.20619 1.93814 5.79381 2.18557 5.54639L2.81787 4.91409C3.06529 4.66667 3.47766 4.66667 3.75258 4.91409L8.01375 9.14777L12.2474 4.91409C12.5223 4.66667 12.9347 4.66667 13.1821 4.91409L13.8144 5.54639C14.0619 5.79381 14.0619 6.20619 13.8144 6.4811L8.45361 11.8144C8.20619 12.0619 7.79381 12.0619 7.54639 11.8144Z"
            fill="#A3A3A3" />
        </svg>
      </div>
    </div>

    <div *ngIf="order.expanded" class="athletes">
      <div class="horizontal-line"></div>
      <div *ngFor="let item of order.orderItemList">
        <div class="ath-header">
          <div class="f-row">
            <span class="ath-name">{{ getName(item.person) }}</span>
            <span class="ath-data">{{ item.person.gender | transloco }}</span>
            <span class="ath-data">{{ "Age " + getAge(item.person) }}</span>
          </div>
          <div class="coverage-type">
            <span *ngIf="item.additionalFieldFirst === 'EXTENDED'">{{
              "EXTENDED_BENEFIT" | transloco
              }}</span>
            <svg *ngIf="item.additionalFieldFirst === 'EXTENDED'" width="4" height="4" viewBox="0 0 4 4" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="#0E2A8D" />
            </svg>
            <span>{{ getCoverageType(item.additionalFieldFirst === 'EXTENDED') | transloco }}</span>
          </div>
          <div class="f-row">
            <span class="ath-price">{{ order.currencyCode + " " + (item.price | number : "1.2-2") }}</span>
          </div>
        </div>
    </div>
  </div>
</div>

