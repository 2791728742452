import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { environment } from "src/environments/environment";
import { Router, RouterModule } from "@angular/router";
import { SafePipe } from "src/app/shared/pipes/safe.pipe";
import { TypeofPipe } from "src/app/shared/pipes/typeof.pipe";
import { LocalizationModule } from "../localization";
import { SafeTypes } from "src/app/shared/constants/safe-types.enum";
import { ApplicationService } from "../../shared/constants/application.service";
import { TranslocoService } from "@ngneat/transloco";
import { Store } from "@ngxs/store";
import { UserState } from "src/app/store/user/user.store";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [
    CommonModule,
    LocalizationModule,
    RouterModule,
    TypeofPipe,
    SafePipe,
  ],
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() isDiffUrl!: boolean;
  currentLang: string = "en";

  constructor(
    public router: Router,
    private applicationService: ApplicationService,
    private store: Store,
    private transloco: TranslocoService
  ) {}

  public SafeTypes = SafeTypes;
  public baseUrl = environment.BASEURL.slice(
    environment.BASEURL.indexOf("/") + 2,
    -1
  );

  public menu: any = [
    {
      name: "HOME",
      link: "/",
      show: true,
      exact: true,
    },
    {
      name: "EVENTS",
      link: "/events",
      show: true,
    },
    {
      name: "STUDIOS",
      link: "/hub-studios",
      show: true,
    },
    {
      name: "",
      type: "sep",
      show: false,
    }, 
    {
      name: "RANKINGS",
      link: `https://portal.${this.baseUrl}/#rankings`,
      show: true,
      outside: true,
      self: true,
    },
    {
      name: "STORE",
      link: "/store/list",
      show: true,
    },
    {
      name: "TV",
      link: "https://youtube.com/@Uventex/videos",
      show: true,
      outside: true,
    },
    {
      name: "ATHLETES",
      link: "/athletes",
      show: true,
    },
    {
      name: "MEMBERSHIPS",
      link: "/memberships",
      show: true,
    },
    {
      name: "NEWS",
      link: `https://www.uventexlabs.com/blog`,
      show: true,
      outside: true,
    },
  ];

  ngOnInit() {
    this.configureMenu();

    this.transloco.langChanges$.subscribe((lang) => {
      const currentLang = lang.split("-")[0];
      this.currentLang = currentLang;
      this.configureUrlRanking();
    });

    this.configureUrlRanking();
  }
  configureUrlRanking() {
    let returnUrl = window.location.href;
    returnUrl = encodeURIComponent(returnUrl).replaceAll("%2F", "___");

    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;

    const rankingURL = `${environment.EVENT_REPORT_URL}GWTClient.html?&locale=${this.currentLang}#rankings;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;

    this.menu = [
      {
        name: "HOME",
        link: "/",
        show: true,
        exact: true,
      },
      {
        name: "EVENTS",
        link: "/events",
        show: true,
      },
      {
        name: "STUDIOS",
        link: "/hub-studios",
        show: true,
      },
      {
        name: "",
        type: "sep",
        show: false,
      },
      {
        name: "RANKINGS",
        link: rankingURL,
        show: true,
        outside: true,
        self: true,
      },
      {
        name: "STORE",
        link: "/store/list",
        show: true,
      },
      {
        name: "TV",
        link: "https://youtube.com/@Uventex/videos",
        show: true,
        outside: true,
      },
      {
        name: "ATHLETES",
        link: "/athletes",
        show: true,
      },
      {
        name: "MEMBERSHIPS",
        link: "/memberships",
        show: true,
      },
      {
        name: "NEWS",
        link: `https://www.uventexlabs.com/blog`,
        show: true,
        outside: true,
      },
    ];
  }

  configureMenu() {
    this.applicationService.getInfo().subscribe((response: any) => {
      if (response) {
        const league = this.menu.find(
          (option: any) => option.link === "/memberships"
        );
        if (league) {
          league.show = !response.isSubdomain;
        }
      }
    });
  }
}
