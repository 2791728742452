<div class="custom-component-theme cc" tabindex="0">
  <div class="custom-sselect" (click)="focus()" [ngClass]="{ errorc: error }">
    <div class="selected-item" #divC [ngClass]="{ errorc: error }">
      <div class="c-label-input">
        <span
          class="custom-floating-label"
          *ngIf="value || inputFocus"
          [ngClass]="{ errorc: error }"
          >{{
            label && label !== "emptyLabel" ? (label | transloco) : "\u00A0"
          }}</span
        >

        <div
          class="c-label"
          *ngIf="!value && !inputFocus"
          [ngClass]="{ errorc: error }"
        >
          {{ label && label !== "emptyLabel" ? (label | transloco) : "\u00A0" }}
        </div>

        <input
          tabindex="0"
          *ngIf="!applyPhoneMask && (inputFocus || value)"
          class="main-title"
          [ngClass]="{ errorc: error }"
          #input
          [type]="type ? type : 'text'"
          [(ngModel)]="value"
          (input)="changeInput()"
        />
        <input
          *ngIf="applyPhoneMask && (inputFocus || value)"
          class="main-title"
          [ngClass]="{ errorc: error }"
          #input
          type="text"
          [(ngModel)]="value"
          (input)="changeInput()"
          phoneMask
        />
      </div>
      <img *ngIf="imageUrl" [src]="imageUrl" />
    </div>
  </div>
  <span class="error" *ngIf="error"> {{ error | transloco }}</span>
</div>
