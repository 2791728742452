import { Component, Input } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgClass, NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";

import {
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from "@angular/core";
import { PhoneMaskDirective } from "src/app/shared/directives/phone-mask.directive";

@Component({
  selector: "app-custom-material-input",
  templateUrl: "./custom-material-input.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    NgClass,
    MatFormFieldModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatInputModule,
    LocalizationModule,
    PhoneMaskDirective,
  ],
  styleUrls: ["./custom-material-input.component.scss"],
})
export class CustomMaterialInputComponent {
  @Input() label?: string;
  @Input() value?: string;
  @Input() type?: string;
  @Input() error?: string;
  @Input() imageUrl?: string;
  @Input() applyPhoneMask?: boolean;
  inputFocus: boolean = false;

  @ViewChild("input") input!: ElementRef<HTMLInputElement>;
  @ViewChild("divC") divC!: ElementRef<any>;

  @Output() valueChanged = new EventEmitter<string>();

  @HostListener("document:click", ["$event"])
  onClickOutside(event: MouseEvent) {
    const clickedInside = this.divC?.nativeElement.contains(event.target);
    if (!clickedInside && this.inputFocus) {
      this.inputFocus = false;
    }
  }

  constructor() {}

  focus() {
    this.inputFocus = true;
    if (this.inputFocus) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 50);
    }
  }

  changeInput() {
    this.valueChanged.emit(this.value?.trim());
  }
}
