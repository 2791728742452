import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { Subscription } from "rxjs";

@Directive({
  selector: "[phoneMask]",
  standalone: true,
})
export class PhoneMaskDirective implements OnInit, OnDestroy {
  private subscription: Subscription | undefined;

  constructor(private el: ElementRef, private ngControl: NgControl) {}

  ngOnInit(): void {
    // Subscribe to valueChanges to catch programmatic updates.
    this.subscription = this.ngControl.valueChanges?.subscribe((value) => {
      if (value && typeof value === "string") {
        const formatted = this.formatPhoneNumber(value);
        // Only update if the formatted value is different to avoid an infinite loop.
        if (formatted !== value) {
          this.ngControl.control?.setValue(formatted, { emitEvent: false });
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // Also handle user input events.
  @HostListener("input", ["$event.target.value"])
  onInput(value: string): void {
    const formatted = this.formatPhoneNumber(value);
    // Update the model without emitting an event to avoid loop.
    this.ngControl.control?.setValue(formatted, { emitEvent: false });
  }

  private formatPhoneNumber(value: string): string {
    // Remove all non-digit characters.
    let digits = value.replace(/\D/g, "");

    // Format phone number as "(xxx) xxx xxxx".
    if (digits.length > 3 && digits.length <= 6) {
      digits = `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    } else if (digits.length > 6) {
      digits = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)} ${digits.slice(
        6,
        10
      )}`;
    }

    return "  " + digits;
  }
}
