<i class="uv-modal-close fas fa-times" (click)="dialogResult.emit(null)"></i>
<div class="wrap-carousel">
    <div class="event-gallery__featured" [ngClass]="{onlyImage: !(params.banners && (params.banners.length > 1))}">
        <owl-carousel-o [options]="customOptions" #owlCar (changed)="changedByNav($event)">
            <ng-container *ngFor="let image of params.banners">
                <ng-template carouselSlide [id]="image.url">
                    <img  [ngClass]="getAspectRatioClass(image)" [src]="image.url">
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>

    <div class="event-gallery__carousel">
        <owl-carousel-o [options]="{dots:false, margin:5, autoWidth: true}" *ngIf="params.banners && params.banners.length > 1">
            <ng-container *ngFor="let image of params.banners; let i = index">
                <ng-template carouselSlide [id]="image.url" [width]="80">
                    <a (click)="owlCar.to(image.url); changeImage(image.url)">
                        <img [src]="image.url"
                        [ngClass]="{'event-gallery__carousel-item--active': selectedImage === image.url || (i === 0 && !selectedImage)}">
                    </a>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>